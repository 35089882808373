import { ReactNode } from 'react'
import NextLink from 'next/link'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from '@/src/stitches.config'
import {
    Arrow,
    ButtonContentSpacer,
    ContentWrapper,
} from '@/src/common/components/marketing/buttons/ButtonStyledComponents'

interface ButtonProps {
    variant:
        | 'primary'
        | 'secondary'
        | 'primaryOutline'
        | 'secondaryOutline'
        | 'whiteOutlineTransparent'
        | 'solid'
        | 'outline'
    size?: 'sm' | 'md' | 'lg'
    label: string
    textTransform?: 'uppercase' | 'lowercase' | 'none'
    url: string | object
    builderBlock?: any
    builderState?: any
    buttonWidth?: string
    isExternalUrl?: boolean
    linkAttributes?: object
    showArrow?: boolean
    onClick?: Function
    onMouseDown?: Function
    className?: 'string'
    'data-testid'?: string
    style?: object
}

const sharedButtonStyles = {
    color: '$white',
    transition: 'background-color 150ms ease',
    border: '2px solid transparent',
}

export const StyledLinkButton = styled('a', {
    fontWeight: 500,
    letterSpacing: '0.05em',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none',
    textAlign: 'center',
    '&:disabled': {
        background: '$mediumGray',
        borderColor: '$mediumGray',
        cursor: 'not-allowed',
        '&:hover, &:focus': {
            background: '$light',
        },
    },
    variants: {
        variant: {
            primary: {
                backgroundColor: '$primary',
                ...sharedButtonStyles,
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$primary',
                    backgroundColor: '$primaryLight',
                    color: '$primary',
                },
            },
            primaryOutline: {
                color: '$primary',
                backgroundColor: '$white',
                transition: 'background-color 150ms ease',
                border: '2px solid $primary',
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$primary',
                    backgroundColor: '$primaryLight',
                },
            },
            secondary: {
                backgroundColor: '$secondary',
                ...sharedButtonStyles,
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$secondary',
                    backgroundColor: '$secondaryLight',
                    color: '$secondary',
                },
            },
            secondaryOutline: {
                color: '$secondary',
                backgroundColor: '$white',
                transition: 'background-color 150ms ease',
                border: '2px solid $secondary',
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$secondary',
                    backgroundColor: '$secondaryLight',
                },
            },
            whiteOutlineTransparent: {
                color: '$white',
                backgroundColor: '$transparent',
                transition: 'background-color 150ms ease',
                border: '3px solid $white',
                '&:not(:disabled):hover, &:not(:disabled):focus': {
                    borderColor: '$white',
                    backgroundColor: '$white',
                    color: '$primary',
                },
            },
        },
        size: {
            sm: {
                borderRadius: '48px',
                maxWidth: '100%',
                px: '$4',
                py: '$2',
                fontSize: '$3',
                lineHeight: '1.25',
            },
            md: {
                borderRadius: '48px',
                width: '275px',
                maxWidth: '100%',
                px: '$3',
                py: '$2',
                fontSize: '$5',
                lineHeight: '1.25',
            },
            lg: {
                borderRadius: '48px',
                width: '100%',
                maxWidth: '100%',
                px: '$4',
                py: '$2',
                fontSize: '$5',
                lineHeight: '1.25',
                '@md': {
                    fontSize: '$6',
                    minWidth: '350px',
                },
            },
        },
        textTransform: {
            uppercase: {
                textTransform: 'uppercase',
            },
            lowercase: {
                textTransform: 'lowercase',
            },
            none: {
                textTransform: 'unset',
            },
        },
    },
    defaultVariants: {
        size: 'md',
        variant: 'primary',
    },
})

interface ConditionalWrapperProps {
    condition: boolean
    wrapper: any
    children: any
}

// @ts-ignore
const ConditionalWrapper = ({
    condition,
    wrapper,
    children,
}: ConditionalWrapperProps) => (condition ? children : wrapper(children))

function determineButtonContent(showArrow: boolean, label: string) {
    if (!showArrow) {
        return label
    }

    return (
        <ContentWrapper>
            <ButtonContentSpacer />
            {label}
            <Arrow>
                <FontAwesomeIcon icon={faArrowRight} />
            </Arrow>
        </ContentWrapper>
    )
}

function determineVariantName(variant: ButtonProps['variant']) {
    if (variant === 'solid') {
        return 'primary'
    } else if (variant === 'outline') {
        return 'primaryOutline'
    }
    return variant
}

/**
 * Primary UI component for user interaction
 */
export const LinkButton = ({
    variant = 'primary',
    size = 'md',
    textTransform,
    label,
    url,
    buttonWidth,
    isExternalUrl = false,
    linkAttributes = {},
    builderState,
    builderBlock,
    showArrow = true,
    onClick = () => {},
    onMouseDown = () => {},
    style = {},
    ...props
}: ButtonProps) => {
    // backwards compatibility with previously configured builder components
    const variantName: ButtonProps['variant'] = determineVariantName(variant)

    // Add the link attributes from builder (or code) to the props that get
    // passed into StyledLinkButton
    props = Object.assign(props, {
        ...linkAttributes,
        href: url,
        className: props.className ?? 'link-button',
        'data-testid': props['data-testid'] ?? 'link-button',
    })

    return (
        <ConditionalWrapper
            condition={isExternalUrl}
            wrapper={(children: ReactNode) => (
                <NextLink href={url} passHref legacyBehavior>
                    {children}
                </NextLink>
            )}
        >
            {/* @ts-ignore*/}
            <StyledLinkButton
                role={'button'}
                variant={variantName}
                size={size}
                textTransform={textTransform}
                css={{
                    width: buttonWidth ?? '',
                }}
                onClick={() => onClick()}
                onMouseDown={() => onMouseDown()}
                style={style}
                {...props}
            >
                {determineButtonContent(showArrow, label)}
            </StyledLinkButton>
        </ConditionalWrapper>
    )
}

export default LinkButton
