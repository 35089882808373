const urlToTabMap: { [key: string]: number } = {
    '/visa-gift-cards': 1,
    '/brands': 1,
    '/perfectgift-plus': 1,
    '/custom-greeting-cards': 1,
    '/occasions': 1,
    '/promotions': 1,
    '/native-app/app-cart': 2,
    '/checkout': 2,
    '/login': 3,
    '/account': 3,
    '/native-app/more': 4,
    '/corporate': 4,
    '/check-balance': 4,
    '/activate': 4,
    '/gift-guides': 4,
    '/careers': 4,
    '/press-media': 4,
    '/order-status': 4,
}

export default urlToTabMap
