import Card from '@/src/common/components/marketing/cards/Card'
import Image from 'next/image'
import { GiftBox } from '@/src/modules/gift-box/content/StyledComponents'

type TBoxImageWithCardProps = {
    designImage: string
    priority?: boolean
    isSmbCard?: boolean
}

const SimplifiedBoxImageWithCard = ({
    designImage,
    priority = false,
    isSmbCard = false,
}: TBoxImageWithCardProps) => {
    return (
        <GiftBox className={'gift-box-wrapper'}>
            <Image
                src={'/images/gift-box-image-v2.png'}
                alt={'PerfectGift Box Image'}
                width={640}
                height={438}
                className={'gift-box-image'}
                priority={priority}
            />
            <Card
                size={'card-for-box'}
                image={designImage}
                overlayType={isSmbCard ? 'partial-smb' : 'full'}
                isExtended={false}
                showLoader={!designImage}
                priority={priority}
                showCustomFields={false}
                borderRadius={5}
            />
        </GiftBox>
    )
}

export default SimplifiedBoxImageWithCard
