import { styled, theme } from '@/src/stitches.config'

export const FooterWrapper = styled('footer', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})

export const FooterTop = styled('div', {
    backgroundColor: '$darkest',
    color: '$white',
    py: '2.55rem',
    px: '1rem',
    width: '100%',
})

export const SectionList = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '@md': {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        gap: '0',
    },
})

export const SectionListItem = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    '@md': {
        justifyContent: 'normal',
        padding: '0 2rem',
        '&:last-child': {
            borderRight: 'none',
        },
    },
})

export const SectionListItemContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
})

export const LinkHeaderContent = styled('p', {
    color: '$white',
    fontSize: '$5',
    marginTop: '0',
    marginBottom: '0',
    borderBottom: '1px solid white',
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.5em',
    '@md': {
        borderBottom: 'none',
        '& svg': {
            display: 'none',
        },
        lineHeight: 'inherit',
    },
    '& svg.down': {
        transform: 'rotate(-180deg)',
        transition: 'transform 0.3s ease-out',
    },

    '& svg': {
        transform: 'rotate(0deg)',
        transition: 'transform 0.3s ease-out',
    },
})

export const SectionListItemInnerContent = styled('div', {
    flexDirection: 'column',
    gap: '.5rem',
    transition: 'height 0.3s ease',
    overflow: 'hidden',
    '@md': {
        display: 'flex !important',
        overflow: 'unset',
        '& .pusher': {
            display: 'none',
        },
    },
    variants: {
        isOpen: {
            false: {
                display: 'flex',
                '& .pusher': {
                    marginTop: ' -100%',
                    transition: 'margin-top .3s ease',
                },
            },
            true: {
                display: 'flex',
                marginBottom: '1rem',
                '& .pusher': {
                    marginTop: '0%',
                    transition: 'margin-top .3s ease',
                },
            },
        },
    },
})

export const StyledLink = styled('a', {
    fontSize: '$2',
    '&:hover': {
        textDecoration: 'underline',
    },
})

export const FooterBottom = styled('div', {
    backgroundColor: '$white',
    padding: '1rem',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '@md': {
        flexDirection: 'row',
    },
})

export const FooterBottomLinks = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.5rem',
    fontSize: '$2',
    '@md': {
        gap: '.75rem',
        width: '59%',
    },
})

export const FooterBottomLink = styled('a', {
    color: '$darkest',
    '&:hover': {
        textDecoration: 'underline',
    },
    '&:before': {
        content: '|',
        mr: '.5rem',
        '@md': {
            mr: '.75rem',
        },
    },
})

export const FooterBottomLogos = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.75rem',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '82%',
    mt: '1.25rem',
    '@md': {
        mt: 'unset',
        gap: '1rem',
        width: '30%',
        justifyContent: 'flex-end',
    },
})

export const RatingWrapper = styled('span', {
    display: 'flex',
    gap: '.35rem',
})

export const TermsAndConditionsText = styled('p', {
    margin: '0 1rem 1rem',
    fontSize: '$2',
    color: theme.colors.dark,
    '@sm': {
        margin: '0 4rem 1rem',
    },
})

export const PhoneNumberWithTextWrapper = styled('div', {
    fontSize: '$2',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
    '& a': {
        textDecoration: 'underline',
    },
})

export const styles = {
    marginTop: {
        marginTop: '1.5rem',
    },
    grayText: {
        color: theme.colors.mediumGray,
        '&:hover': {
            textDecoration: 'none',
        },
    },
}

export const ImageWrapper = styled('div', {})
