import NextLink from 'next/link'
import {
    SUPPORT_PHONE_NUMBER_FORMATTED,
    SUPPORT_PHONE_NUMBER_TEL,
} from '@/src/common/components/marketing/text/PhoneNumbers/strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import {
    FooterWrapper,
    FooterTop,
    SectionList,
    SectionListItem,
    SectionListItemContent,
    SectionListItemInnerContent,
    LinkHeaderContent,
    PhoneNumberWithTextWrapper,
    RatingWrapper,
    StyledLink,
    ImageWrapper,
    FooterBottomLink,
    FooterBottomLinks,
    FooterBottom,
    FooterBottomLogos,
    TermsAndConditionsText,
    styles,
} from '@/src/modules/footer/StyledComponents'
import ConsumerAndCorporateFooterLegal from './ConsumerAndCorporateFooterLegal'

interface IWhiteLabelProgramFooterProps {
    programName: string
    programNiceName: string
    disableFooterTop: boolean
    customDisclaimer?: string
}

export const WhiteLabelProgramFooter = ({
    programName,
    programNiceName,
    disableFooterTop,
    customDisclaimer,
}: IWhiteLabelProgramFooterProps) => {
    const [isOpen, setIsOpen] = useState<boolean[]>([
        false,
        true,
        false,
        false,
        false,
        false,
    ])

    const toggleDropdown = (index: number) => {
        const oldIsOpen = [...isOpen]
        oldIsOpen[index] = !oldIsOpen[index]
        setIsOpen(oldIsOpen)
    }

    return (
        <FooterWrapper>
            {!disableFooterTop && (
                <>
                    <FooterTop>
                        <SectionList>
                            <SectionListItem>
                                <SectionListItemContent>
                                    <LinkHeaderContent
                                        onClick={() => toggleDropdown(2)}
                                    >
                                        Gifting
                                        <FontAwesomeIcon
                                            icon={faAngleUp}
                                            className={isOpen[2] ? 'down' : ''}
                                        />
                                    </LinkHeaderContent>
                                    <SectionListItemInnerContent
                                        isOpen={isOpen[2]}
                                    >
                                        <div className={'pusher'}></div>

                                        <NextLink
                                            href={`/purchase`}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Physical {programNiceName} Gift
                                                Cards
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={`/purchase`}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Digital {programNiceName} Gift
                                                Cards
                                            </StyledLink>
                                        </NextLink>
                                    </SectionListItemInnerContent>
                                </SectionListItemContent>
                            </SectionListItem>

                            <SectionListItem>
                                <SectionListItemContent>
                                    <LinkHeaderContent
                                        onClick={() => toggleDropdown(5)}
                                    >
                                        24/7 Support provided by PerfectGift.com
                                        <FontAwesomeIcon
                                            icon={faAngleUp}
                                            className={isOpen[5] ? 'down' : ''}
                                        />
                                    </LinkHeaderContent>
                                    <SectionListItemInnerContent
                                        isOpen={isOpen[5]}
                                    >
                                        <div className={'pusher'}></div>
                                        <PhoneNumberWithTextWrapper>
                                            Call us:{' '}
                                            <a
                                                role={'button'}
                                                data-test-id={
                                                    'footerSupportPhoneNumber'
                                                }
                                                href={SUPPORT_PHONE_NUMBER_TEL}
                                            >
                                                {SUPPORT_PHONE_NUMBER_FORMATTED}
                                            </a>
                                        </PhoneNumberWithTextWrapper>

                                        <NextLink
                                            href={`/order-status`}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Track your order
                                            </StyledLink>
                                        </NextLink>

                                        <StyledLink
                                            rel="noopener"
                                            target="_blank"
                                            href="https://perfectgiftsupport.zendesk.com/hc/en-us"
                                        >
                                            FAQs & Help center
                                        </StyledLink>
                                    </SectionListItemInnerContent>
                                </SectionListItemContent>
                            </SectionListItem>
                        </SectionList>
                    </FooterTop>
                </>
            )}

            <ConsumerAndCorporateFooterLegal
                customDisclaimer={customDisclaimer}
            />
        </FooterWrapper>
    )
}
export default WhiteLabelProgramFooter
