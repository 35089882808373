import { CartProvider } from '@/src/common/contexts/CartContext'
import { FC, useEffect, useState } from 'react'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import {
    setAdaChatMetaField,
    setSensitiveAdaChatMetaField,
} from '@/src/common/utilities/setAdaChatMetaFields'
import useGetFirebaseToken from '@/src/common/hooks/useGetFirebaseToken'
import { TPartnerPayload } from '@/src/modules/partners/types'
import {
    hasDisplayCutout,
    isNativeAndroidDevice,
} from '@/src/common/deviceInfo'
import AppHeader from '@/src/common/components/elements/Layout/Navigation/NativeApps/headers/AppHeader'
import { styled } from '@stitches/react'
import BottomNav from '@/src/common/components/elements/Layout/Navigation/NativeApps/BottomNav'
import { useRouter } from 'next/router'
import { useIsNative } from '@/src/common/contexts/IsNativeContext'
import { useKeyboardContext } from '@/src/common/contexts/KeyboardContext'
import { safeCapacitorCall } from '@/src/common/utilities/capacitorUtils'

interface ContentData {
    data?: {
        disableHeader?: boolean
        disableFooter?: boolean
        disableFooterTop?: boolean
    }
}
interface PagePropsInterface {
    content?: ContentData
    consumerBanner?: any
    corporateBanner?: any
    showFooter?: boolean
    showHeader?: boolean
    partnerData?: TPartnerPayload
}

interface AppLayout {
    Component: Function // This is a Page, which is a React Functional Component. May want to make this interface be more specific here.
    pageProps: PagePropsInterface
}

const MainContent = styled('main', {
    paddingTop: '56px',
})

const AppStatusBarPadding = styled('div', {
    variants: {
        padding: {
            ios: {
                paddingTop: '50px',
            },
            android: {
                paddingTop: '30px',
            },
        },
    },
})

const BottomNavPadding = styled('div', {
    variants: {
        padding: {
            default: {
                height: '88px',
            },
            cutout: {
                height: '100px',
            },
            noCutout: {
                height: '100px',
            },
        },
    },
})

const KeyboardPadding = styled('div', {
    height: '350px',
})

const AppLayout: FC<AppLayout> = ({ Component, pageProps }) => {
    const router = useRouter()
    const showHeader =
        (pageProps?.showHeader ?? true) &&
        !(pageProps?.content?.data?.disableHeader ?? false)
    const { user, isLoggedIn, isLoaded } = useUser()
    const { data: firebaseToken, refetch: refetchFirebaseToken } =
        useGetFirebaseToken()
    const userPhone: string = user?.phoneNumber ?? ''
    const userEmail: string = user?.email ?? ''
    const clearAdaValue: boolean = isLoaded && !isLoggedIn
    const { isKeyboardOpen } = useKeyboardContext()
    const [statusPaddingVariant, setStatusPaddingVariant] = useState<
        'ios' | 'android'
    >('ios')
    const [navPaddingVariant, setNavPaddingVariant] = useState<
        'default' | 'cutout' | 'noCutout'
    >('default')
    const [shouldShowHeader, setShouldShowHeader] = useState(false)
    const isNative = useIsNative()

    useEffect(() => {
        const updateBottomNavPadding = async () => {
            if (isNativeAndroidDevice) {
                setStatusPaddingVariant('android')
                const hasCutout = await safeCapacitorCall(hasDisplayCutout)
                if (hasCutout) {
                    setNavPaddingVariant('cutout')
                } else {
                    setNavPaddingVariant('noCutout')
                }
            }
        }
        updateBottomNavPadding()
    }, [])

    useEffect(() => {
        refetchFirebaseToken().then()
    }, [refetchFirebaseToken, user])

    // if user is logged in, send their firebase uid to the chatbot
    // both setMetaFields and setSensitiveMetaFields are available for setting info in chat
    useEffect(() => {
        setSensitiveAdaChatMetaField({
            firebase_token: !clearAdaValue ? firebaseToken : '',
        })

        // @todo determine if we really want/need to pass phone and email in
        setAdaChatMetaField({
            phone_number: !clearAdaValue ? userPhone : '',
            email: !clearAdaValue ? userEmail : '',
        })
    }, [clearAdaValue, firebaseToken, userEmail, userPhone])

    useEffect(() => {
        // Determine if the current route is one of the routes that should not show the header
        const shouldShowHeader =
            ![
                '/personalized-visa-gift-card',
                '/predesigned-visa-gift-card',
                '/perfectgift-plus/build',
                '/virtual-visa-gift-cards',
                '/visa-gift-card-box',
                '/native-app/choose-contacts',
            ].includes(router.pathname) &&
            !router.pathname.startsWith('/brands/')

        setShouldShowHeader(shouldShowHeader)
    }, [router.pathname])

    return (
        <AppStatusBarPadding padding={statusPaddingVariant}>
            <CartProvider>
                {showHeader && shouldShowHeader && <AppHeader />}
                {isNative && (
                    <MainContent>
                        <Component {...pageProps} />
                    </MainContent>
                )}
                {!isKeyboardOpen && isNative && (
                    <>
                        <BottomNav />
                        <BottomNavPadding padding={navPaddingVariant} />
                    </>
                )}
                {isKeyboardOpen && isNativeAndroidDevice && <KeyboardPadding />}
            </CartProvider>
        </AppStatusBarPadding>
    )
}

export default AppLayout
