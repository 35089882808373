import { Capacitor } from '@capacitor/core'
import { getDisplayInsets } from '../plugins/DisplayInsetsPlugin'

let cachedHasCutout: boolean | null = null

const platform = Capacitor.getPlatform()
const isNativeApp = Capacitor.isNativePlatform()
const isNativeIOSDevice = platform === 'ios' && isNativeApp
const isNativeAndroidDevice = platform === 'android' && isNativeApp

const hasDisplayCutout = async (): Promise<boolean> => {
    if (isNativeAndroidDevice) {
        if (cachedHasCutout !== null) {
            return cachedHasCutout
        }
        const result = await getDisplayInsets()
        cachedHasCutout = result.hasCutout
        return result.hasCutout
    }
    return false
}

export {
    isNativeApp,
    isNativeIOSDevice,
    isNativeAndroidDevice,
    hasDisplayCutout,
}
