import { useEffect, useState } from 'react'
import {
    BaseNavItem,
    SubSection,
} from '@/src/common/components/elements/Layout/Navigation/StyledComponents'
import Link from 'next/link'
import { INavItemProps } from '@/src/common/components/elements/Layout/Navigation/Mobile/types'
import NavigationHeader from '@/src/common/components/elements/Layout/Navigation/Mobile/NavigationHeader'
import NavigationSubSection from '@/src/common/components/elements/Layout/Navigation/Mobile/NavigationSubSection'
import { styled } from '@/src/stitches.config'

const SubLabel = styled('div', {
    fontSize: '.875rem',
    margin: '10px 12.5px',
    marginTop: '-5px',
    fontWeight: 'bold',
    color: `$dark`,
    variants: {
        hasIcon: {
            true: {
                paddingLeft: 0,
            },
            false: {
                paddingLeft: 30,
            },
        },
    },
})

const NavigationItem = ({
    item,
    menuIsClosing,
    closeMenu,
    style = {},
}: INavItemProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        if (menuIsClosing) {
            setIsOpen(false)
        }
    }, [menuIsClosing])

    return (
        <BaseNavItem>
            {item?.url ? (
                <Link passHref href={item.url}>
                    <NavigationHeader
                        item={item}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        style={style}
                        closeMenu={closeMenu}
                    />
                </Link>
            ) : (
                <NavigationHeader
                    item={item}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    closeMenu={closeMenu}
                    style={style}
                />
            )}
            {item.subLabel && (
                <SubLabel hasIcon={!!item?.icon}>{item.subLabel}</SubLabel>
            )}
            {isOpen && (
                <SubSection>
                    {item?.subSection?.map((section) => {
                        return (
                            <NavigationSubSection
                                section={section}
                                key={section.testId}
                                closeMenu={closeMenu}
                            />
                        )
                    })}
                </SubSection>
            )}
        </BaseNavItem>
    )
}

export default NavigationItem
