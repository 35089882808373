import NavItemInterface from './NavItemInterface'
import Link from 'next/link'
import determineNavLinkContent from '@/src/common/components/elements/Navigation/determineNavLinkContent'
import { NavigationSectionHeader } from '@/src/common/components/elements/Navigation/StyledComponents'

interface INavigationMenuDropdownItem extends NavItemInterface {
    image?: string
    gradient_words?: string
    icon_class?:
        | 'fa-briefcase'
        | 'fa-circle-plus'
        | 'fa-right-to-bracket'
        | 'fa-store'
        | 'fa-user-plus'
        | 'fa-gift'
        | 'fa-home'
        | 'fa-gear'
        | 'fa-phone'
        | 'fa-comment-dots'
        | 'fa-comments'
        | 'fa-envelope'
        | 'fa-square-question'
        | 'fa-credit-card'
        | 'fa-truck-fast'
        | 'fa-receipt'
        | 'fa-user'
    is_external_url?: boolean
    openChatBox?: boolean
    onClick?: Function
}

export const NavigationMenuDropdownItem = ({
    label,
    label_suffix,
    sub_label,
    url,
    image,
    gradient_words,
    test_id,
    is_view_all,
    icon_class,
    is_external_url = false,
    openChatBox = false,
    onClick = Function,
}: INavigationMenuDropdownItem) => {
    if (!url) {
        return <NavigationSectionHeader>{label}</NavigationSectionHeader>
    }

    const linkContent = determineNavLinkContent({
        image,
        gradient_words,
        is_view_all,
        icon_class,
        openChatBox,
        sub_label,
        label,
        label_suffix,
    })

    if (openChatBox) {
        return linkContent
    }

    if (is_external_url) {
        return (
            <a
                role={'button'}
                onClick={() => onClick()}
                href={url}
                key={url}
                data-testid={test_id ?? ''}
            >
                {linkContent}
            </a>
        )
    }

    return (
        <Link
            role={'button'}
            onClick={() => onClick()}
            href={url}
            key={url}
            data-testid={test_id ?? ''}
            passHref
        >
            {linkContent}
        </Link>
    )
}

export default NavigationMenuDropdownItem
