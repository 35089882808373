import dynamic from 'next/dynamic'

export const DynamicHeaderSearchBar = dynamic(
    () =>
        import('@/src/modules/algolia/header-search/HeaderSearchBar').then(
            (mod) => mod.HeaderSearchBar
        ),
    {
        ssr: false,
        loading: () => <div style={{ width: '100%', height: '36px' }} />,
    }
)
