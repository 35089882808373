export const uuidOriginalPancakeHouse = '3e0ef1c7-2b9f-404e-bae1-087a187838db'
export const uuidDunkin = '837d7545-91fd-440b-9913-e40aa0c9bfcb'
export const uuidLowes = 'c7cc8f62-14c9-4740-b915-196cf66a6ef2'
export const uuidBurgatory = '29ec1a76-ce90-46b6-bbab-4296e076f124'
export const uuidBojangles = 'd34f817f-72f1-46cb-984e-b3e66d9a7f3c'
export const uuidVisa = '2402708a-f8f3-4a05-ae4c-aa724d248c8e'
export const uuidPgPlus = '58f71946-546a-415e-b211-8a23fbd6a021'

export const brandsData = [
    {
        brand_name: 'The Original Pancake House',
        uuid: uuidOriginalPancakeHouse,
        pgc_enabled: true,
        giftya_merchant_id: '5c99b9bc-71ed-40ef-ba9b-3867c8eb8c2f',
        giftya_image:
            'https://res.cloudinary.com/gift-card-granny/image/upload/v1712173813/PGSite/marketing_images/the-original-pancake-house_qumz5f.jpg',
        giftya_enabled: true,
        giftya_card_to_card_enabled: true,
        giftya_egift_enabled: true,
        giftya_zelle_enabled: true,
        giftya_min_value: 500,
        giftya_max_value: 10000,
        BrandCategories: {
            connectOrCreate: [
                {
                    where: { slug: 'card_to_card' },
                    create: { name: 'Card-to-Card', slug: 'card_to_card' },
                },
                {
                    where: { slug: 'restaurants' },
                    create: { name: 'Restaurants', slug: 'restaurants' },
                },
            ],
        },
    },
    {
        brand_name: 'Dunkin',
        uuid: uuidDunkin,
        pgc_enabled: true,
        giftya_merchant_id: '0036f3aa-4337-4fae-8761-e575b8eceeba',
        giftya_image:
            'https://res.cloudinary.com/gift-card-granny/image/upload/w_400,c_scale,q_75/GY/merchant_images/dunkin_arbszt.png',
        giftya_enabled: true,
        giftya_card_to_card_enabled: true,
        giftya_egift_enabled: true,
        giftya_plastic_enabled: true,
        giftya_zelle_enabled: true,
        giftya_min_value: 500,
        giftya_max_value: 10000,
        BrandCategories: {
            connectOrCreate: [
                {
                    where: { slug: 'card_to_card' },
                    create: { name: 'Card-to-Card', slug: 'card_to_card' },
                },
                {
                    where: { slug: 'restaurants' },
                    create: { name: 'Restaurants', slug: 'restaurants' },
                },
            ],
        },
    },
    {
        brand_name: "Lowe's",
        uuid: uuidLowes,
        pgc_enabled: true,
        giftya_merchant_id: '90249251-03e1-422c-b231-6c235229ce06',
        giftya_image:
            'https://res.cloudinary.com/gift-card-granny/image/upload/w_400,c_scale,q_75/GY/merchant_images/Lowe_s_wrxs9y.png',
        giftya_enabled: true,
        giftya_card_to_card_enabled: true,
        giftya_egift_enabled: true,
        giftya_plastic_enabled: true,
        giftya_zelle_enabled: true,
        giftya_min_value: 500,
        giftya_max_value: 10000,
        giftya_fixed_denominations: { fixed_denominations: [999, 1500] },
        BrandCategories: {
            connectOrCreate: [
                {
                    where: { slug: 'card_to_card' },
                    create: { name: 'Card-to-Card', slug: 'card_to_card' },
                },
                {
                    where: { slug: 'shopping' },
                    create: { name: 'Shopping', slug: 'shopping' },
                },
                {
                    where: { slug: 'home_garden' },
                    create: { name: 'Home & garden', slug: 'home_garden' },
                },
            ],
        },
    },
    {
        brand_name: 'Burgatory',
        uuid: uuidBurgatory,
        pgc_enabled: true,
        giftya_merchant_id: '9269bfc5-1ee0-4a5c-aef4-5041e1b7c930',
        giftya_image:
            'https://res.cloudinary.com/gift-card-granny/image/upload/v1711646939/PGSite/marketing_images/burgatory_juxcte.jpg',
        giftya_enabled: true,
        giftya_card_to_card_enabled: true,
        giftya_egift_enabled: false,
        giftya_plastic_enabled: true,
        giftya_zelle_enabled: true,
        giftya_min_value: 500,
        giftya_max_value: null,
        BrandCategories: {
            connectOrCreate: [
                {
                    where: { slug: 'card_to_card' },
                    create: { name: 'Card-to-Card', slug: 'card_to_card' },
                },
                {
                    where: { slug: 'restaurants' },
                    create: { name: 'Restaurants', slug: 'restaurants' },
                },
            ],
        },
    },
    {
        brand_name: 'Bojangles',
        uuid: uuidBojangles,
        pgc_enabled: true,
        giftya_merchant_id: '1f618b7d-0c30-4f2e-aedf-8f6b86b8a61e',
        giftya_image:
            'https://res.cloudinary.com/gift-card-granny/image/upload/v1726259327/PGSite/marketing_images/bo_family-meals_vf_r90h2v.png',
        giftya_enabled: true,
        giftya_card_to_card_enabled: true,
        giftya_egift_enabled: true,
        giftya_zelle_enabled: true,
        giftya_min_value: 500,
        giftya_max_value: 10000,
        BrandCategories: {
            connectOrCreate: [
                {
                    where: { slug: 'card_to_card' },
                    create: { name: 'Card-to-Card', slug: 'card_to_card' },
                },
                {
                    where: { slug: 'restaurants' },
                    create: { name: 'Restaurants', slug: 'restaurants' },
                },
            ],
        },
    },
]
