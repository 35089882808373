import { useEffect } from 'react'
import HeaderCtaButtons from '@/src/common/components/elements/Layout/HeaderCtaButtons'
import HeaderHelpMenu from '@/src/common/components/elements/Layout/HeaderHelpMenu'
import HeaderUserMenu from '@/src/common/components/elements/Layout/HeaderUserMenu'
import NavigationMenuWithHover from '@/src/common/components/elements/Layout/Navigation/NavigationMenuWithHover'
import desktopNavData from '@/public/desktopNavMenuData.json'
import desktopNavCorporateData from '@/public/desktopNavMenuCorporateData.json'
import HeaderCartButton from '@/src/common/components/elements/Layout/HeaderCartButton'
import { ICart } from '@/src/modules/cart/ICart'
import { removeLineItemFromCart } from '@/src/common/utilities/removeLineItemFromCart'
import { useCookies } from 'react-cookie'
import {
    PG_CART_SESSION_UUID,
    PROMO_CODE,
    WL_CART_STATE_UUID,
} from '@/config/cookieNames'
import usePostAddCart from '@/src/common/hooks/usePostAddCart'
import buildGoogleEcommerceItemsFromCart from '@/src/common/utilities/analytics/buildGoogleEcommerceItemsFromCart'
import { showCartEvent } from '@/src/common/utilities/gtag'
import { getLineItemsForCart } from '@/src/modules/cart/utilities'
import { removeBoxItemFromCart } from '@/src/modules/checkout/utilities/removeBoxItemFromCart'
import CartFlyout from '@/src/common/components/elements/Layout/CartFlyout'
import { useCartContext } from '@/src/common/contexts/CartContext'
import {
    HeaderBottom,
    HeaderTop,
    HeaderTopContentRight,
    HeaderWrapper,
    HideContentMdDown,
    HideOnNonMobile,
} from '@/src/common/components/elements/Navigation/header/StyledComponents'
import HeaderLogoAndMobileNav from '@/src/common/components/elements/Navigation/header/HeaderLogoAndMobileNav'
import desktopNavWhiteLabelMenuData from '@/public/desktopNavWhiteLabelMenuData.json'
import { TPartnerPayload } from '@/src/modules/partners/types'
import { useRouter } from 'next/router'
import useGetCartManually from '@/src/common/hooks/useGetCartManually'
import {
    TWhiteLabelProgramFrontEnd,
    WhiteLabelProgramConfig,
} from '@/src/modules/whiteLabelProgram/types'
import { DynamicHeaderSearchBar } from '@/src/modules/algolia/header-search/DynamicHeaderSearchBar'


interface IHeaderProps {
    isCorporate: boolean
    partnerData?: TPartnerPayload
    whiteLabelProgram?: TWhiteLabelProgramFrontEnd
}

const Header = ({
    isCorporate,
    partnerData,
    whiteLabelProgram,
}: IHeaderProps) => {
    const router = useRouter()
    const { setCartFlyoutOpen, cartData, setCartData, hasSMBCard } =
        useCartContext()
    const { isLoading: refetchCartIsLoading } = useGetCartManually()
    const { mutate: updateCart, isLoading: postCartIsLoading } =
        usePostAddCart()
    const [cookies, setCookie] = useCookies([
        PG_CART_SESSION_UUID,
        WL_CART_STATE_UUID,
        PROMO_CODE,
    ])
    const isLoading: boolean = refetchCartIsLoading || postCartIsLoading

    const partnerName: string = partnerData?.partnerName ?? ''
    const partnerCartUuid: string = cookies[WL_CART_STATE_UUID]
    const isSingleMerchantPartner: boolean =
        !!partnerData?.store_page_brand_slug && !whiteLabelProgram

    const desktopNavItems = () => {
        if (
            whiteLabelProgram &&
            !whiteLabelProgram?.program_config?.main_menu_items
        ) {
            const navData = desktopNavWhiteLabelMenuData.map((item) => {
                return {
                    ...item,
                    label: item.label.replace(
                        /{whiteLabelProgramName}/g,
                        whiteLabelProgram?.name ?? ''
                    ),
                    url: item.url,
                }
            })
            return navData
        } else if (whiteLabelProgram?.program_config?.main_menu_items) {
            return whiteLabelProgram?.program_config?.main_menu_items
        } else {
            return desktopNavData
        }
    }

    const { lineItemsInBoxes, lineItemsNotInBox } =
        getLineItemsForCart(cartData)
    const cartItemCount: number =
        lineItemsNotInBox.length + lineItemsInBoxes.length

    function removeItemFromCart({
        lineItemUuid,
        boxUuid,
    }: {
        lineItemUuid?: string
        boxUuid?: string
    }) {
        let newCartData = null

        if (lineItemUuid) {
            newCartData = removeLineItemFromCart(
                cartData as ICart,
                lineItemUuid
            )
        }
        if (boxUuid) {
            newCartData = removeBoxItemFromCart(cartData as ICart, boxUuid)
        }

        updateCart(
            {
                ...newCartData,
                isPartnerFlow: !!partnerData && !whiteLabelProgram,
                hasPartnerCart: !!partnerCartUuid,
            },
            {
                onSuccess: (data) => {
                    setCartData(data as ICart)
                },
                onError: (error: any) => {
                    console.error('Issue updating cart:', error)
                },
            }
        )
    }

    // check if promo provided in url param. if so, auto-set
    const promoParam = router?.query?.promo ?? ''
    useEffect(() => {
        if (promoParam) {
            const value =
                typeof promoParam !== 'string' ? promoParam[0] : promoParam

            setCookie(PROMO_CODE, value, {
                path: '/',
                domain: window.location.hostname,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promoParam])

    const fireShowCartEvent = () => {
        if (cartData && cartData?.resolved_data?.items_count > 0) {
            const formattedCartData =
                buildGoogleEcommerceItemsFromCart(cartData)
            showCartEvent(
                cartData.resolved_data.order_total,
                formattedCartData,
                hasSMBCard
            )
        }
    }

    return (
        <HeaderWrapper>
            <HeaderTop>
                <CartFlyout
                    removeItemFromCart={removeItemFromCart}
                    showLoader={isLoading}
                    partnerData={partnerData}
                    whiteLabelProgram={whiteLabelProgram}
                />
                <HeaderLogoAndMobileNav
                    isCorporate={isCorporate}
                    partnerData={partnerData}
                    showMobileNav={true}
                    whiteLabelProgram={whiteLabelProgram}
                    showSearchBar={(!partnerData && !whiteLabelProgram)}
                />
                <HeaderTopContentRight>
                    <HideContentMdDown>
                        <HeaderHelpMenu
                            partner={partnerName}
                            direction={'left'}
                            whiteLabelProgram={whiteLabelProgram}
                        />
                        {!isSingleMerchantPartner && (
                            <HeaderUserMenu
                                enableConsumerDropdownItems={true}
                                isCorporate={isCorporate}
                                partner={partnerName}
                                whiteLabelProgram={whiteLabelProgram}
                            />
                        )}
                        {(!partnerData || whiteLabelProgram) && (
                            <HeaderCtaButtons
                                isCorporate={isCorporate}
                                whiteLabelProgram={whiteLabelProgram}
                            />
                        )}
                    </HideContentMdDown>
                    <HeaderCartButton
                        openCartFlyout={() => {
                            fireShowCartEvent()
                            setCartFlyoutOpen(true)
                        }}
                        itemCount={cartItemCount}
                    />
                </HeaderTopContentRight>
            </HeaderTop>
            {(!partnerData || whiteLabelProgram) && (
                <>
                    {!whiteLabelProgram &&
                        <HideOnNonMobile style={{ padding: '.5rem' }}>
                            <DynamicHeaderSearchBar />
                        </HideOnNonMobile>
                    }
                    <HeaderBottom>
                        <NavigationMenuWithHover
                            menuData={
                                isCorporate
                                    ? desktopNavCorporateData
                                    : desktopNavItems()
                            }
                        />
                    </HeaderBottom>
                </>

            )}
        </HeaderWrapper>
    )
}

export default Header
