import { TBoxItem } from '@/src/modules/cart/ICart'
import {
    ActionButtonsWrapper,
    EditPencil,
    RemoveButton,
} from '@/src/modules/cart/StyledComponents'
import { removeBoxItemAndSendAnalyticEvent } from '@/src/modules/cart/utilities'
import { BOX_URL } from '@/src/modules/checkout/strings'
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COBRAND_BAC_PAGE } from '../../pg-plus/constants'
import { useCartContext } from '@/src/common/contexts/CartContext'

type TBoxRemoveEditProps = {
    removeItemFromCart: Function
    box: TBoxItem
    isSMBCard: boolean
}

const BoxRemoveAndEditButtons = ({
    removeItemFromCart,
    box,
    isSMBCard,
}: TBoxRemoveEditProps) => {
    const urlForEdit = isSMBCard
        ? `${COBRAND_BAC_PAGE}?box_edit_uuid=${box.box_uuid}`
        : `${BOX_URL}?box_edit_uuid=${box.box_uuid}&step=2`

    const { setCartFlyoutOpen } = useCartContext()

    return (
        <ActionButtonsWrapper>
            <EditPencil
                href={urlForEdit}
                onClick={() => {
                    setCartFlyoutOpen(false)
                }}
            >
                <FontAwesomeIcon icon={faPencil} />
            </EditPencil>
            <RemoveButton
                onClick={() =>
                    removeBoxItemAndSendAnalyticEvent({
                        removeItemFromCart: removeItemFromCart,
                        boxItem: box,
                        isSmb: isSMBCard,
                    })
                }
            >
                <FontAwesomeIcon icon={faTrashCan} />
            </RemoveButton>
        </ActionButtonsWrapper>
    )
}

export default BoxRemoveAndEditButtons
