export const API_GIFT_REDEMPTION_BASIC_INFO: string =
    '/api/gift-redemption-basic-info'
export const API_GIFT_REDEMPTION_FULL_INFO: string = '/api/gift-redemption-info'
export const API_GIFT_REDEMPTION_PLASTIC_VISA: string =
    '/api/gift-redemption-plastic'
export const API_GIFT_EXCHANGE_MERCHANT: string = '/api/gift-exchange-merchant'
export const API_EGIFT_EXCHANGE: string = '/api/gift-redemption-egift'
export const API_EGIFT_INFO: string = '/api/gift-redemption-egift-info'
export const API_GIFT_REDEMPTION_ZELLE: string = '/api/gift-redemption-zelle'
export const API_GIFT_REDEMPTION_REGIFT: string = '/api/gift-redemption-regift'
export const API_RECEIVED_GIFTS: string = '/api/account/gifts-received'
export const API_RECEIVED_GIFTS_COUNT: string =
    '/api/account/gifts-received-count'
export const API_RECEIVED_GIFTS_BRAND_LIST: string =
    '/api/account/gifts-received-brand-list'
export const API_GIFT_RECEIVED_DETAILS: string =
    '/api/account/gift-received-details'
export const API_CREATE_CARD_LINKING_SESSION: string =
    '/api/card-linking/card-link-session'
export const API_DEACTIVATE_CARD_LINK: string =
    '/api/card-linking/deactivate-link'
export const API_DELETE_CARD: string = '/api/card-linking/delete-card'
export const API_GIFT_REDEMPTION_CARD_LINK: string =
    '/api/gift-redemption-card-link'
export const API_KYC_APPLICATION: string = '/api/kyc-application'
export const API_DELETE_ACCOUNT: string = '/api/account/delete-account'
export const API_ORDER_HISTORY: string = '/api/account/order-history'
export const GET_GYP_MERCHANT: string = '/api/get-gyp-merchant'
export const GET_GYP_ZELLE_ACCOUNT: string = '/api/get-gyp-zelle-account'
export const API_ORDER_COUNT: string = '/api/account/order-count'
