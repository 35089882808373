import PurchaseFlowMaxAlert from '@/src/common/components/elements/Alerts/PurchaseFlowMaxAlert'
import CartBoxItem from '@/src/common/components/elements/Layout/CartBoxItem'
import CartFlyoutCheckout from '@/src/common/components/elements/Layout/CartFlyoutCheckout'
import CartItem from '@/src/common/components/elements/Layout/CartItem'
import Text from '@/src/common/components/elements/Text/Text'
import LinkButton from '@/src/common/components/marketing/buttons/LinkButton'
import { useCartContext } from '@/src/common/contexts/CartContext'
import useStatusBarAdjustment from '@/src/common/hooks/useStatusBarAdjustment'
import { getLineItemsForCart } from '@/src/modules/cart/utilities'
import { LoadingOverlay } from '@/src/modules/checkout/forms/StyledComponents'
import { TPartnerPayload } from '@/src/modules/partners/types'
import determineBuyPage from '@/src/modules/partners/utilities/determineBuyPage'
import { DEFAULT_BUY_PAGE } from '@/src/modules/pg-plus/constants'
import { styled } from '@/src/stitches.config'
import { faClose } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/legacy/image'
import Drawer from 'react-modern-drawer'
import { ClipLoader } from 'react-spinners'
import useAppPaddingTop from '@/src/common/hooks/useGetAppPaddingTop'
import { useEffect, useState } from 'react'
import {
    hasDisplayCutout,
    isNativeAndroidDevice,
    isNativeApp,
    isNativeIOSDevice,
} from '@/src/common/deviceInfo'
import { TWhiteLabelProgramFrontEnd } from '@/src/modules/whiteLabelProgram/types'

const CartFlyoutDrawer = styled(Drawer, {
    maxWidth: '500px',
    width: '100% !important',
    bc: '$lightest',
    display: 'flex',
    fd: 'column',
    variants: {
        emptyCart: {
            true: {
                justifyContent: 'flex-start',
            },
            false: {
                justifyContent: 'space-between',
            },
        },
        padding: {
            default: {
                paddingBottom: '0px',
            },
            ios: {
                paddingBottom: '50px',
            },
            androidCutout: {
                paddingBottom: '62px',
            },
            androidNoCutout: {
                paddingBottom: '26px',
            },
        },
        nativeApp: {
            true: {
                width: '100% !important',
                maxWidth: '100% !important',
            },
            false: {
                width: '100% !important',
                maxWidth: '500px',
            },
        },
    },
})

const Container = styled('div', {
    display: 'flex',
    fd: 'column',
    jc: 'space-between',
    height: '95%',
    width: '100%',
    margin: '0 auto auto',
})

const CartHeadingContainer = styled('div', {
    display: 'flex',
    ai: 'center',
    p: '0 1rem',
    color: '$darkest',
    textTransform: 'uppercase',
    variants: {
        emptyCart: {
            true: {
                justifyContent: 'flex-end',
            },
            false: {
                justifyContent: 'space-between',
            },
        },
    },
})

const CloseWrapper = styled('button', {
    margin: '10px 0px 10px 10px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: 'inherit',
    border: 'none',
    fontSize: 18,
})

const CartItemsContainer = styled('div', {
    display: 'flex',
    fd: 'column',
    flex: 1,
    oy: 'auto',
})

const EmptyCartContainer = styled('div', {
    display: 'flex',
    fd: 'column',
    ai: 'center',
    jc: 'center',
    p: '1rem',
})

const ScrollableWrapper = styled('div', {
    display: 'flex',
    fd: 'column',
    flex: 1,
    overflowY: 'auto',
})

const EmptyCartHeadingText = styled(Text, {
    fontSize: '$7',
    fontWeight: 'bold',
    color: '$darkest',
    m: '3rem auto 1rem',
    '@sm': {
        m: '5rem auto 1rem',
    },
})

const EmptyCartSubheadingText = styled(Text, {
    fontSize: '$5',
    color: '$dark',
    m: '24px 10px',
    ta: 'center',
    '@sm': {
        m: '24px 48px',
    },
})

const EmptyCartButtonContainer = styled('div', {
    m: '1.5rem auto 1rem',
    '& a': {
        fontSize: '$4',
        '@sm': {
            fontSize: '$6',
        },
    },
    paddingBottom: '15px',
})

const ExtraPaddingContainer = styled('div', {
    marginBottom: '35px',
})

interface CartFlyoutProps {
    removeItemFromCart: Function
    showLoader: boolean
    partnerData?: TPartnerPayload
    whiteLabelProgram?: TWhiteLabelProgramFrontEnd
    alwaysOpen?: boolean
    isNative?: boolean
}

const CartFlyout = ({
    showLoader,
    removeItemFromCart,
    partnerData,
    whiteLabelProgram,
    alwaysOpen,
    isNative,
}: CartFlyoutProps) => {
    const {
        cartFlyoutOpen,
        setCartFlyoutOpen,
        cartData: cart,
        orderLimitDetails,
        hasSMBCard,
    } = useCartContext()
    const { requiresKyc: requiresKYC, consumerOrderIsOverWeeklyLimit } =
        orderLimitDetails

    const lineItems = getLineItemsForCart(cart)
    const { lineItemsInBoxes, lineItemsNotInBox } = lineItems
    const continueShoppingUrl: string = determineBuyPage(
        DEFAULT_BUY_PAGE,
        partnerData,
        whiteLabelProgram
    )
    const cartIsEmpty = lineItemsInBoxes.length + lineItemsNotInBox.length === 0

    const isSingleMerchantPartner: boolean =
        !!partnerData?.store_page_brand_slug
    const emptyCartText: string = isSingleMerchantPartner
        ? 'Add a gift'
        : 'Find the perfect gift'

    //adds padding to accommodate for the bottom nav and status bar on native apps
    const [paddingTop, setPaddingTop] = useState('0px')
    const appPaddingTop = useAppPaddingTop()
    const [paddingVariant, setPaddingVariant] = useState<
        'default' | 'ios' | 'androidCutout' | 'androidNoCutout'
    >('default')
    const [isNativeAndInLandscape, setIsNativeAndInLandscape] =
        useState<boolean>(false)
    const { isLandscape } = useStatusBarAdjustment()

    useEffect(() => {
        const updateBottomNavPadding = async () => {
            if (isNativeIOSDevice) {
                setPaddingVariant('ios')
            } else if (isNativeAndroidDevice) {
                const hasCutout = await hasDisplayCutout()
                if (hasCutout) {
                    setPaddingVariant('androidCutout')
                } else {
                    setPaddingVariant('androidNoCutout')
                }
            }
        }
        updateBottomNavPadding()
    }, [])

    useEffect(() => {
        const padding = `${
            parseInt(appPaddingTop, 10) + (isNativeApp ? 64 : 0)
        }px`
        setPaddingTop(padding)
    }, [appPaddingTop, isNativeApp])

    useEffect(() => {
        setIsNativeAndInLandscape(isLandscape && isNativeApp)
    }, [isLandscape, isNativeApp])

    useEffect(() => {
        if (alwaysOpen) {
            setCartFlyoutOpen(true)
        }
    }, [alwaysOpen, setCartFlyoutOpen])

    return (
        <CartFlyoutDrawer
            open={alwaysOpen || cartFlyoutOpen}
            onClose={() => {
                if (!alwaysOpen) {
                    setCartFlyoutOpen(false)
                }
            }}
            direction={'right'}
            emptyCart={cartIsEmpty}
            customIdSuffix={'_pgc_cart_flyout_drawer'}
            padding={paddingVariant}
            nativeApp={isNative}
            style={{ paddingTop }}
        >
            {showLoader && (
                <LoadingOverlay style={{ width: '100%' }}>
                    <ClipLoader color={'black'} loading={true} size={30} />
                </LoadingOverlay>
            )}
            <CartHeadingContainer emptyCart={cartIsEmpty}>
                {cartIsEmpty ? '' : 'Cart'}
                {!isNative && (
                    <CloseWrapper
                        onClick={() => setCartFlyoutOpen(false)}
                        tabIndex={0}
                        aria-label="Close cart"
                    >
                        <FontAwesomeIcon icon={faClose} size={'lg'} />
                    </CloseWrapper>
                )}
            </CartHeadingContainer>
            {cart && !cartIsEmpty ? (
                <Container style={{ paddingBottom: isNative ? 35 : 0 }}>
                    <CartItemsContainer style={{ margin: 10 }}>
                        {requiresKYC && (
                            <PurchaseFlowMaxAlert
                                hitsWeeklyLimit={consumerOrderIsOverWeeklyLimit}
                                hasSMBCard={hasSMBCard}
                                isFlyout={true}
                                size={'sm'}
                                style={{ paddingTop: 7, paddingBottom: 7 }}
                            />
                        )}
                        {lineItemsInBoxes?.map((box) => {
                            return (
                                <CartBoxItem
                                    key={`item-${box.box_uuid}`}
                                    box={box}
                                    removeItemFromCart={removeItemFromCart}
                                />
                            )
                        })}
                        {lineItemsNotInBox?.map((item, index) => {
                            const isLastItem: boolean =
                                index === lineItemsNotInBox?.length - 1

                            return (
                                <CartItem
                                    key={item.line_item_uuid}
                                    cartLineItem={item}
                                    removeItemFromCart={removeItemFromCart}
                                    partnerData={partnerData}
                                    hideBottomBorder={isLastItem}
                                />
                            )
                        })}
                        {isNativeAndInLandscape && (
                            <ExtraPaddingContainer>
                                <CartFlyoutCheckout
                                    orderTotal={cart.resolved_data.order_total}
                                    partnerData={partnerData}
                                    continueShoppingUrl={continueShoppingUrl}
                                    whiteLabelProgram={whiteLabelProgram}
                                />
                            </ExtraPaddingContainer>
                        )}
                    </CartItemsContainer>
                    {!isNativeAndInLandscape && (
                        <CartFlyoutCheckout
                            orderTotal={cart.resolved_data.order_total}
                            partnerData={partnerData}
                            continueShoppingUrl={continueShoppingUrl}
                            whiteLabelProgram={whiteLabelProgram}
                        />
                    )}
                </Container>
            ) : (
                <ScrollableWrapper>
                    <EmptyCartContainer>
                        {!isSingleMerchantPartner && (
                            <Image
                                src={'/images/empty_cart_image.png'}
                                alt={'Empty Cart'}
                                width={442}
                                height={326}
                                layout={'intrinsic'}
                                priority={false}
                                blurDataURL={'/images/empty_cart_image.png'}
                            />
                        )}
                        <EmptyCartHeadingText>
                            Your cart is empty.
                        </EmptyCartHeadingText>
                        <EmptyCartSubheadingText>
                            {
                                "Looks like you haven't added anything to your cart yet."
                            }
                        </EmptyCartSubheadingText>
                        <EmptyCartButtonContainer
                            onClick={() => setCartFlyoutOpen(false)}
                        >
                            <LinkButton
                                variant={'primary'}
                                size={'lg'}
                                textTransform={'uppercase'}
                                label={emptyCartText}
                                url={continueShoppingUrl}
                            />
                        </EmptyCartButtonContainer>
                    </EmptyCartContainer>
                </ScrollableWrapper>
            )}
        </CartFlyoutDrawer>
    )
}

export default CartFlyout
