//surrounds a call to a capacitor plugin with a try catch block and logs the error if it occurs
//use this to check to see if the client has the plugin installed before calling it
export async function safeCapacitorCall<T>(
    pluginCall: () => Promise<T>,
    errorHandler: (error: any) => void = console.error
): Promise<T | null> {
    try {
        const result = await pluginCall()
        return result
    } catch (error) {
        errorHandler(error)
        return null
    }
}
