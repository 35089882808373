import React from 'react'
import { styled, theme } from '../../../../stitches.config'

export interface HeadlineEyebrowProps {
    label: string
    className?: string
    style?: object
    hideDash?: boolean
}

const Wrapper = styled('div', {
    fontSize: theme.fontSizes[5],
    color: `${theme.colors.secondary}`,
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: '.1em',
    margin: '0',
})

const Dash = styled('span', {
    paddingRight: '.5em',
})

/**
 * Descriptive keyword or phrase placed above main headline and/or blurb
 * https://uxmovement.com/content/increasing-headline-clicks-with-eyebrow-text/
 */
export const HeadlineEyebrow = ({
    label,
    className = '',
    style = {},
    hideDash = false,
}: HeadlineEyebrowProps) => {
    return (
        <Wrapper
            data-testid={'headlineEyebrow'}
            className={className}
            style={style}
        >
            {!hideDash && <Dash className={'em-dash'}>&mdash;</Dash>}
            {label}
        </Wrapper>
    )
}

export default HeadlineEyebrow
