import { useEffect, useState } from 'react'
import { Router } from 'next/router'
import {
    createWhiteLabeledTheme,
    generateGlobalStyles,
    theme as defaultTheme,
} from '@/src/stitches.config'
import { mapConfigToWhiteLabelSiteTheme } from '@/src/common/utilities/whiteLabelTheme'
import { getWhiteLabelProgramConfigObjectFromProgram } from '@/src/modules/whiteLabelProgram/whiteLabelProgram.service'
import { WhiteLabelProgramSiteThemeConfig } from '@/src/modules/whiteLabelProgram/types'

function isWhiteLabeledPage(router: Router): boolean {
    return router.pathname.startsWith('/_sites/')
}

export function useWhiteLabelTheme(
    router: Router,
    pageProps: any
): typeof defaultTheme {
    let whiteLabelProgramConfig
    let whiteLabelThemeConfig: WhiteLabelProgramSiteThemeConfig | undefined =
        undefined

    if (pageProps?.whiteLabelProgram) {
        whiteLabelProgramConfig = getWhiteLabelProgramConfigObjectFromProgram(
            pageProps?.whiteLabelProgram
        )
        whiteLabelThemeConfig = whiteLabelProgramConfig
            ? mapConfigToWhiteLabelSiteTheme(whiteLabelProgramConfig)
            : undefined
    }

    const initialTheme = whiteLabelThemeConfig
        ? createWhiteLabeledTheme(whiteLabelThemeConfig)
        : defaultTheme
    const [theme, setTheme] = useState(initialTheme)

    useEffect(() => {
        if (isWhiteLabeledPage(router) && whiteLabelThemeConfig) {
            setTheme(createWhiteLabeledTheme(whiteLabelThemeConfig))
        } else {
            setTheme(defaultTheme)
        }
        generateGlobalStyles()
    }, [router.asPath])

    // Themes should be extensions of defaulTheme, so we can safely cast it to typeof defaultTheme
    return theme as typeof defaultTheme
}
