import {
    isNativeIOSDevice,
    isNativeAndroidDevice,
    isNativeApp,
} from '@/src/common/deviceInfo'

const getAppPaddingTop = (isLandscape: boolean): string => {
    if (!isNativeApp) return '0px'
    if (isNativeIOSDevice) return isLandscape ? '4px' : '48px'
    if (isNativeAndroidDevice) {
        return '26px'
    }
    return '0px'
}

export default getAppPaddingTop
