import { styled } from '@/src/stitches.config'
import Text from '../Text/Text'
import LinkButton from '../../marketing/buttons/LinkButton'
import NextLink from 'next/link'
import Image from 'next/legacy/image'
import { PARTNER_CHECKOUT_PAGE } from '@/src/modules/pg-plus/constants'
import { TPartnerPayload } from '@/src/modules/partners/types'
import { useCartContext } from '@/src/common/contexts/CartContext'
import { usePartnerTheme } from '@/src/common/contexts/PartnerThemeContext'
import determineCheckoutUrls from '@/src/modules/checkout/utilities/determineCheckoutUrls'
import { useEffect, useState } from 'react'
import { set } from 'lodash'
import { TWhiteLabelProgramFrontEnd } from '@/src/modules/whiteLabelProgram/types'

const CheckoutSection = styled('div', {
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flex: 1,
    jc: 'center',
    bc: 'white',
    p: '0.5rem',
    maxHeight: '265px',
    boxShadow: 'lightgray 8px 6px 6px 6px',
})

const Container = styled('div', {
    display: 'flex',
    flex: 1,
    fd: 'column',
    ai: 'center',
})

const OrderTotalContainer = styled('div', {
    display: 'flex',
    fd: 'row',
    jc: 'space-between',
    width: '100%',
    p: '0.5rem',
    m: '7px 0 10px',
})

const OrderTotalLabel = styled(Text, {
    color: '$darkest',
    fontSize: '$5',
    fontWeight: 'bold',
    '@sm': {
        fontSize: '$6',
    },
})

const OrderTotalValue = styled(Text, {
    color: '$primary',
    fontSize: '$5',
    fontWeight: 'bold',
    '@sm': {
        fontSize: '$6',
    },
})

const Separator = styled('hr', {
    m: 'auto 1rem',
    borderTop: '1px solid $mediumGray',
    borderBottom: 'none',
    width: '100%',
})

const CheckoutButtonContainer = styled('div', {
    m: '23px auto 17px',
    minWidth: '275px',
    '& a': {
        fontSize: '$4',
        '@sm': {
            fontSize: '$5',
        },
    },
})

const ContinueShoppingLink = styled('a', {
    color: '$primary',
    fontSize: '$4',
    fontWeight: 'bold',
    textDecoration: 'underline',
    '@sm': {
        fontSize: '$5',
    },
})

const NortonBBBImageContainer = styled('div', {
    display: 'flex',
    fd: 'row',
    m: '20px auto 5px',
    columnGap: '1rem',
})

interface CartFlyoutCheckoutProps {
    orderTotal: string
    continueShoppingUrl: string
    partnerData?: TPartnerPayload
    whiteLabelProgram?: TWhiteLabelProgramFrontEnd
}

const CartFlyoutCheckout = ({
    orderTotal,
    continueShoppingUrl,
    partnerData,
    whiteLabelProgram,
}: CartFlyoutCheckoutProps) => {
    const { setCartFlyoutOpen } = useCartContext()
    const checkoutUrl: string =
        partnerData && !whiteLabelProgram ? PARTNER_CHECKOUT_PAGE : '/checkout'

    const [
        continueShoppingUrlWithBasePath,
        setContinueShoppingUrlWithBasePath,
    ] = useState<string>(continueShoppingUrl)
    useEffect(() => {
        setContinueShoppingUrlWithBasePath(`${continueShoppingUrlWithBasePath}`)
    }, [])

    return (
        <CheckoutSection>
            <Container>
                <OrderTotalContainer>
                    <OrderTotalLabel>Order Total:</OrderTotalLabel>
                    <OrderTotalValue>{`$${orderTotal}`}</OrderTotalValue>
                </OrderTotalContainer>
                <Separator />
                <CheckoutButtonContainer
                    onClick={() => setCartFlyoutOpen(false)}
                >
                    <LinkButton
                        variant={'primary'}
                        size={'lg'}
                        label={'Checkout'}
                        url={checkoutUrl}
                    />
                </CheckoutButtonContainer>
                <NextLink
                    href={continueShoppingUrlWithBasePath}
                    passHref
                    legacyBehavior
                >
                    <ContinueShoppingLink
                        onClick={() => setCartFlyoutOpen(false)}
                    >
                        Continue Shopping
                    </ContinueShoppingLink>
                </NextLink>
                <NortonBBBImageContainer>
                    <a
                        href="https://www.bbb.org/us/pa/pittsburgh/profile/gift-cards/perfect-gift-llc-0141-71066921"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <Image
                            src="https://seal-westernpennsylvania.bbb.org/seals/blue-seal-120-61-bbb-71066921.png"
                            width="68"
                            height="39"
                            alt="Better Business Bureau Logo"
                        />
                    </a>
                </NortonBBBImageContainer>
            </Container>
        </CheckoutSection>
    )
}

export default CartFlyoutCheckout
