import { useEffect, useState } from 'react'
import Image from 'next/image'
import {
    hasDisplayCutout,
    isNativeAndroidDevice,
} from '@/src/common/deviceInfo'
import { styled } from '@stitches/react'
import { getLineItemsForCart } from '@/src/modules/cart/utilities'
import { useCartContext } from '@/src/common/contexts/CartContext'
import { useRouter } from 'next/router'
import buildGoogleEcommerceItemsFromCart from '@/src/common/utilities/analytics/buildGoogleEcommerceItemsFromCart'
import { showCartEvent } from '@/src/common/utilities/gtag'
import urlToTabMap from '@/src/common/components/elements/Layout/Navigation/NativeApps/urlToTabMap'
import { checkButtonNavigation } from '@/src/plugins/NavigationModePlugin'
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar'
import { safeCapacitorCall } from '@/src/common/utilities/capacitorUtils'
import { useUser } from '@/src/modules/auth/context/AuthProvider'

const BottomNavContainer = styled('div', {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: '#FFFFFF',
    zIndex: 1000,
    boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px 16px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
    paddingLeft: '16px',
    paddingRight: '16px',
    variants: {
        variant: {
            default: {
                height: '80px',
                paddingBottom: '16px',
            },
            cutout: {
                height: '92px',
                paddingBottom: '30px',
            },
            noCutout: {
                height: '56px',
                paddingBottom: '0px',
            },
            extraPadding: {
                height: '110px',
                paddingBottom: '46px',
            },
            cutoutExtraPadding: {
                height: '95px',
                paddingBottom: '38px',
            },
            noCutoutExtraPadding: {
                height: '95px',
                paddingBottom: '38px',
            },
        },
    },
})

export const NavItem = styled('button', {
    minWidth: '60px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    position: 'relative',
})

const CartItemCounter = styled('div', {
    position: 'absolute',
    right: '14px',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: '#2C73FF',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    variants: {
        position: {
            default: {
                top: '8px',
            },
            cutout: {
                top: '6px',
            },
            noCutout: {
                top: '2px',
            },
            extraPadding: {
                top: '10px',
            },
            cutoutExtraPadding: {
                top: '8px',
            },
            noCutoutExtraPadding: {
                top: '4px',
            },
        },
    },
})

const BottomNav = () => {
    const { user } = useUser()
    const { cartData, hasSMBCard } = useCartContext()
    const { lineItemsInBoxes, lineItemsNotInBox } =
        getLineItemsForCart(cartData)
    const cartItemCount: number =
        lineItemsNotInBox.length + lineItemsInBoxes.length

    const [navVariant, setNavVariant] = useState<
        | 'default'
        | 'cutout'
        | 'noCutout'
        | 'extraPadding'
        | 'cutoutExtraPadding'
        | 'noCutoutExtraPadding'
    >('default')

    const [selectedTab, setSelectedTab] = useState(0)
    const router = useRouter()

    const fireShowCartEvent = () => {
        if (cartData && cartData?.resolved_data?.items_count > 0) {
            const formattedCartData =
                buildGoogleEcommerceItemsFromCart(cartData)
            showCartEvent(
                cartData.resolved_data.order_total,
                formattedCartData,
                hasSMBCard
            )
        }
    }

    useEffect(() => {
        const updateNavStyles = async () => {
            const hasNavButtons = await checkButtonNavigation()
            const hasCutout = await hasDisplayCutout()
            if (isNativeAndroidDevice) {
                if (hasNavButtons && hasCutout) {
                    setNavVariant('cutoutExtraPadding')
                } else if (hasNavButtons) {
                    setNavVariant('noCutoutExtraPadding')
                } else if (hasCutout) {
                    setNavVariant('cutout')
                } else {
                    setNavVariant('noCutout')
                }

                await safeCapacitorCall(() =>
                    NavigationBar.setColor({
                        color: '#000000',
                        darkButtons: true,
                    })
                )
            }
        }

        updateNavStyles()
    }, [])

    //updates the tab bar when the route changes
    useEffect(() => {
        const handleRouteChange = (url: string) => {
            if (url !== '/') {
                const matchedPath = Object.keys(urlToTabMap).find((key) =>
                    url.startsWith(key)
                )
                if (matchedPath !== undefined) {
                    setSelectedTab(urlToTabMap[matchedPath])
                }
            } else {
                setSelectedTab(0)
            }
        }

        // Initial check
        handleRouteChange(router.asPath)

        // Listen for route changes
        router.events.on('routeChangeComplete', handleRouteChange)

        // Cleanup the event listener on component unmount
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events, setSelectedTab])

    const renderIcon = (src: string, selectedSrc: string, index: number) => (
        <Image
            src={selectedTab === index ? selectedSrc : src}
            alt="icon"
            width={48}
            height={48}
        />
    )

    const handleNavigation = (newValue: number) => {
        setSelectedTab(newValue)
        switch (newValue) {
            case 0:
                router.push('/native-app/app-homepage')
                break
            case 1:
                router.push('/visa-gift-cards')
                break
            case 2:
                fireShowCartEvent()
                router.push('/native-app/app-cart')
                break
            case 3:
                user ? router.push('/account') : router.push('/login')
                break
            case 4:
                router.push('/native-app/more')
                break
            default:
                break
        }
    }

    return (
        <BottomNavContainer as="nav" variant={navVariant}>
            <NavItem onClick={() => handleNavigation(0)}>
                {renderIcon(
                    '/images/native-app/bottom-navigation/home.svg',
                    '/images/native-app/bottom-navigation/home_selected.svg',
                    0
                )}
            </NavItem>
            <NavItem onClick={() => handleNavigation(1)}>
                {renderIcon(
                    '/images/native-app/bottom-navigation/buy.svg',
                    '/images/native-app/bottom-navigation/buy_selected.svg',
                    1
                )}
            </NavItem>
            <NavItem
                onClick={() => {
                    fireShowCartEvent()
                    handleNavigation(2)
                }}
            >
                {renderIcon(
                    '/images/native-app/bottom-navigation/cart.svg',
                    '/images/native-app/bottom-navigation/cart_selected.svg',
                    2
                )}
                {cartItemCount > 0 && (
                    <CartItemCounter position={navVariant}>
                        {cartItemCount}
                    </CartItemCounter>
                )}
            </NavItem>
            <NavItem onClick={() => handleNavigation(3)}>
                {renderIcon(
                    user
                        ? '/images/native-app/bottom-navigation/account.svg'
                        : '/images/native-app/bottom-navigation/login.svg',
                    user
                        ? '/images/native-app/bottom-navigation/account_selected.svg'
                        : '/images/native-app/bottom-navigation/login_selected.svg',
                    3
                )}
            </NavItem>
            <NavItem onClick={() => handleNavigation(4)}>
                {renderIcon(
                    '/images/native-app/bottom-navigation/more.svg',
                    '/images/native-app/bottom-navigation/more_selected.svg',
                    4
                )}
            </NavItem>
        </BottomNavContainer>
    )
}

export default BottomNav
