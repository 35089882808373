import {
    ActionButtonsWrapper,
    EditPencil,
    RemoveButton,
} from '@/src/modules/cart/StyledComponents'
import { removeCartItem } from '@/src/modules/cart/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { ILineItem } from '@/src/modules/cart/ICart'
import determineEditLink from '@/src/modules/checkout/utilities/determineEditLink'
import { TPartnerPayload } from '../../partners/types'
import { useCartContext } from '@/src/common/contexts/CartContext'

type TLineItemProps = {
    lineItemUuid: string
    mediumType: string
    brandMerchant: string
    cartLineItem: ILineItem
    removeItemFromCart: Function
    isPhysical: boolean
    isVirtual: boolean
    isCardToCard: boolean
    isPgPlus: boolean
    isSMBCard: boolean
    partnerData?: TPartnerPayload
}

const LineItemRemoveAndEditButtons = ({
    removeItemFromCart,
    cartLineItem,
    lineItemUuid,
    isPhysical,
    isCardToCard,
    isVirtual,
    mediumType,
    brandMerchant,
    isPgPlus,
    isSMBCard,
    partnerData,
}: TLineItemProps) => {
    const editLink: string = determineEditLink({
        isPhysical,
        isVirtual,
        isCardToCard,
        isPgPlus,
        isSMBCard,
        partnerData,
        brandSlug: brandMerchant,
        line_item_uuid: lineItemUuid,
    })

    const { setCartFlyoutOpen } = useCartContext()

    return (
        <ActionButtonsWrapper>
            <EditPencil
                onClick={() => {
                    setCartFlyoutOpen(false)
                }}
                href={editLink}
            >
                <FontAwesomeIcon icon={faPencil} />
            </EditPencil>
            <RemoveButton
                onClick={() =>
                    removeCartItem({
                        removeItemFromCart: removeItemFromCart,
                        line_item_uuid: lineItemUuid,
                        cartLineItem: cartLineItem,
                        mediumType: mediumType,
                        isSmb: isSMBCard,
                    })
                }
            >
                <FontAwesomeIcon icon={faTrashCan} />
            </RemoveButton>
        </ActionButtonsWrapper>
    )
}

export default LineItemRemoveAndEditButtons
