import {
    WhiteLabelProgramSiteThemeConfig,
    WhiteLabelProgramConfig,
} from '@/src/modules/whiteLabelProgram/types'

export function mapConfigToWhiteLabelSiteTheme(
    whiteLabelProgramConfig: WhiteLabelProgramConfig
): WhiteLabelProgramSiteThemeConfig {
    return {
        primaryColor: whiteLabelProgramConfig.primary_color ?? '',
        secondaryColor: whiteLabelProgramConfig.secondary_color ?? '',
        aBtnTextAlignment: 'right', // just a random CSS prop we will use for demonstrations
    }
}
