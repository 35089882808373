export const loadFacebookSDK = () => {
    return new Promise((resolve) => {
        if (document.getElementById('facebook-jssdk')) {
            resolve(true)
            return
        }

        const script = document.createElement('script')
        script.id = 'facebook-jssdk'
        script.src = 'https://connect.facebook.net/en_US/sdk.js'
        script.onload = () => {
            window.FB.init({
                appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID, // Replace with your Facebook App ID
                cookie: true,
                xfbml: true,
                version: 'v18.0', // Use latest version of Graph API
            })
            resolve(true)
        }
        document.body.appendChild(script)
    })
}
