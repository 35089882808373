import { styled } from '@/src/stitches.config'
import Image from 'next/image'
import Card from '@/src/common/components/marketing/cards/Card'
import Carrier from '../../marketing/products/Carrier'

const Wrapper = styled('div', {
    width: '100%',
    position: 'relative',
    '.greeting-card-image': {
        width: '57%',
        height: 'auto',
        boxShadow: 'gray 0px 1px 10px -1px',
    },
    '.card-image': {
        position: 'absolute',
        bottom: 8,
        right: 0,
        border: '2px solid $white',
        maxWidth: '75% !important',
    },
    '.emboss-fields': {
        fontSize: 'clamp(.25vw, 7px, 7px)',
    },
    variants: {
        variant: {
            carrier: {
                // adjust carrier styles in cart so it's more compatible
                '.carrier': {
                    maxWidth: '57%',
                },
                '.support-details, .custom-details': {
                    fontSize: '.3vw',
                    '@sm': {
                        fontSize: '.2vw',
                    },
                    '@md': {
                        fontSize: '.1vw',
                    },
                },
                '.custom-details': {
                    '& p': { margin: '0px 0px 2px' },
                },
                '.gift-expiration': {
                    display: 'none',
                },
            },
        },
    },
})

interface IGreetingCardItem {
    greetingCardImage: string
    cardImage: string
    isSmbCard: boolean
    name?: string
    message?: string
    denomination?: string
    priority?: boolean
}

const GiftCardWithCarrier = ({
    greetingCardImage,
    cardImage,
    isSmbCard,
    name,
    message,
    denomination,
    priority = false,
}: IGreetingCardItem) => {
    if (!greetingCardImage) {
        return (
            <Wrapper variant={'carrier'}>
                <Carrier
                    cloudinary={cardImage}
                    denomination={denomination ?? ''}
                    recipientName={'Recipient Name'}
                    recipientMessage={'Recipient Message'}
                    senderName={'Sender Name'}
                />
                <Card
                    size={'sm'}
                    image={cardImage}
                    overlayType={isSmbCard ? 'partial-smb' : 'partial'}
                    isExtended={false}
                    showLoader={!cardImage}
                    priority={priority}
                    name={name}
                    message={message}
                    showCustomFields={!!name || !!message}
                    borderRadius={5}
                />
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Image
                src={greetingCardImage}
                alt={'Greeting Card Image'}
                width={640}
                height={848}
                className={'greeting-card-image'}
                priority={priority}
            />
            <Card
                size={'sm'}
                image={cardImage}
                overlayType={isSmbCard ? 'partial-smb' : 'partial'}
                isExtended={false}
                showLoader={!cardImage}
                priority={priority}
                name={name}
                message={message}
                showCustomFields={!!name || !!message}
                borderRadius={5}
            />
        </Wrapper>
    )
}

export default GiftCardWithCarrier
