import { createContext, useContext, useEffect, useState } from 'react'
import { Capacitor } from '@capacitor/core'

const IsNativeContext = createContext(false)

export function IsNativeProvider({
    children,
}: {
    readonly children: React.ReactNode
}) {
    const [isNative, setIsNative] = useState(false)

    useEffect(() => {
        setIsNative(Capacitor.isNativePlatform())
    }, [])

    return (
        <IsNativeContext.Provider value={isNative}>
            {children}
        </IsNativeContext.Provider>
    )
}

export function useIsNative() {
    return useContext(IsNativeContext)
}
