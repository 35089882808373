import { useEffect, useState } from 'react'
import getAppPaddingTop from 'src/common/utilities/getAppPaddingTop'
import useStatusBarAdjustment from 'src/common/hooks/useStatusBarAdjustment'

const useAppPaddingTop = (): string => {
    const { isLandscape } = useStatusBarAdjustment()
    const [paddingTop, setPaddingTop] = useState('0px')

    useEffect(() => {
        const fetchHasDisplayCutout = async () => {
            const paddingTopValue = getAppPaddingTop(isLandscape)
            setPaddingTop(paddingTopValue)
        }

        fetchHasDisplayCutout()
    }, [isLandscape])

    return paddingTop
}

export default useAppPaddingTop
