import StringHelpers from '@/src/common/utilities/StringHelpers'

export const MEDIUM_TYPE_PHYSICAL: string = 'physical'
export const MEDIUM_TYPE_VIRTUAL: string = 'virtual'
export const MEDIUM_TYPE_CARD_TO_CARD: string = 'card_to_card'
export const CONSUMER_VISA_PLASTIC_PAN: string = '4941490110557466'
export const CONSUMER_VISA_PLASTIC_TOKEN: string =
    'f47b5b30-9204-4761-9e77-68efadec56f7'
export const CORPORATE_VISA_PLASTIC_TOKEN: string =
    '8febd156-57a7-4478-b81d-2cadfef9a5be'
export const CORPORATE_VISA_PLASTIC_PAN: string = '4941490133855285'
export const CARD_TO_CARD_EXTERNAL_ID_DUNKIN: string =
    'NLr42G7_Z20Ub1t3d2ZhE0GPXzHIlBt1fsPJN5uJ5TBT1GdGtT'
export const CARD_TO_CARD_EXTERNAL_ID_UNBRANDED: string =
    'KTJYw99zIBuSswi-s_mhGdEg0tIn80D0dhRe20syzqMU-0smWW'
export const CARD_TO_CARD_EXTERNAL_ID_RANGING_DENOM: string =
    'f720a218-08a0-4890-b6e6-31d87a4209aa'
export const CARD_TO_CARD_REDEMPTION_TOKEN_TEST_RANGING_DENOM: string =
    '2e7b724b-0f29-470f-be52-f193bcba10ec'
export const CARD_TO_CARD_REDEMPTION_CODE_TEST_RANGING_DENOM: string = '1111'
export const CARD_TO_CARD_EXTERNAL_ID_FIXED_DENOM: string =
    '7b4ce5a3-8095-4242-81ec-deb6af61ba12'
export const CARD_TO_CARD_REDEMPTION_TOKEN_TEST_FIXED_DENOM: string =
    'a62d09c7-cc75-4e74-b462-aa80bc97a8f7'
export const CARD_TO_CARD_REDEMPTION_CODE_TEST_FIXED_DENOM: string = '1111'
export const CARD_TO_CARD_EXTERNAL_ID_UNCLAIMED_AT_GYP: string =
    '1ed59804-5f3e-4877-9adc-2f092d777282'
export const CARD_TO_CARD_REDEMPTION_TOKEN_UNCLAIMED_AT_GYP: string =
    '3ed6d272-aacb-431f-822b-96104e715a12'
export const CARD_TO_CARD_REDEMPTION_CODE_UNCLAIMED_AT_GYP: string = '1111'
export const CARD_TO_CARD_UUID_CARD_LINKED_AT_GYP: string =
    'fecb81e2-c96b-4f66-8d7d-f1988b65b275'
export const CARD_TO_CARD_EXTERNAL_ID_CARD_LINKED_AT_GYP: string =
    '187adb92-2bda-44d0-8c75-94880056a79e'
export const CARD_TO_CARD_REDEMPTION_TOKEN_CARD_LINKED_AT_GYP: string =
    'dc228efe-27e8-4110-95ab-2d503489fa27'
export const CARD_TO_CARD_REDEMPTION_CODE_CARD_LINKED_AT_GYP: string = '1111'

export const CARD_TO_CARD_EXTERNAL_ID_EGIFT: string =
    'jqzvt5XUaooSfVNz3OSFa0TYiqCciFo3On92Gvf8--D-7VAYeF'
export const CARD_TO_CARD_EXTERNAL_ID_EGIFT_2: string =
    'facb7bd6-04e3-4a17-91ba-c6d0ebe80224'
export const CARD_TO_CARD_EXTERNAL_ID_PLASTIC: string =
    'Hs9so2ZfV0o5ZPY8sAblL8v8806DZf_7lUnKkFL98fT0acDIys'
export const EGIFT_CARD_GYP_ID: string =
    'wLM_Uj0gSD-oV8bfH3NO-vDmGn34cUet7sH2QkFAxMTL16C21O'
export const VISA_CARD_GYP_ID: string =
    '375KLP84cI2pf9oVZMnD-4IWolh10fcc_8-RcRm9yxKGc79bpQ'
export const CARD_TO_CARD_REDEMPTION_TOKEN_TEST_DUNKIN =
    '617aea02-fa6f-4e5b-9926-9293e06f3e01'
export const CARD_TO_CARD_REDEMPTION_TOKEN_TEST_UNREDEEMED_DUNKIN =
    '235dyf03-e2er-3ld9-8302-09kde5i76p98'
export const CARD_TO_CARD_REDEMPTION_CODE_TEST_DUNKIN = '1111'
export const CARD_TO_CARD_REDEMPTION_TOKEN_TEST_PG_PLUS =
    '248dc8c5-26f5-46cd-a188-d9ca1dc56d31'
export const CARD_TO_CARD_REDEMPTION_CODE_TEST_PG_PLUS = '1111'
export const CARD_TO_CARD_UUID_TEST_PG_PLUS =
    'ed5134b5-4b38-43c9-8335-894ee33fa129'
export const CARD_TO_CARD_REDEMPTION_TOKEN_TEST_EGIFT =
    '6517104b-c88f-41af-a03c-616cc6f31e47'
export const CARD_TO_CARD_REDEMPTION_CODE_TEST_EGIFT = '1111'
export const CARD_TO_CARD_REDEMPTION_TOKEN_TEST_PLASTIC =
    'cadaff53-a2a4-41ea-9a2d-9ae6b9cb9d22'
export const CARD_TO_CARD_REDEMPTION_CODE_TEST_PLASTIC = '1111'
export const CARD_TO_CARD_REDEMPTION_TOKEN_TEST_EXCHANGE_FOR_EGIFT =
    '235bf504-e0a7-492c-839c-7527b5cc8bd1'
export const CARD_TO_CARD_REDEMPTION_CODE_TEST_EXCHANGE_FOR_EGIFT = '1111'
export const VOIDED_REASON_ARTWORK_REJECTED: string = 'artwork_rejected'
export const VOIDED_REASON_COBRAND_REJECTED: string = 'cobrand_rejected'
export const FEDEX_2_DAY = 'fedex-2-day'
export const FEDEX_OVERNIGHT = 'fedex-overnight'
export const LINE_ITEM_TYPE_CARD = 'card'
export const LINE_ITEM_TYPE_CARD_TO_CARD = 'card_to_card'

export const SERVICE_SLUG_EMAIL = 'email'
export const SERVICE_SLUG_2_DAY = '2_day'
export const SERVICE_SLUG_OVERNIGHT = 'overnight'
export const SERVICE_SLUG_OVERNIGHT_PRIORITY = 'overnight_priority'
export const SERVICE_SLUG_SAVER = 'saver'
export const SERVICE_SLUG_FIRST_CLASS = 'first_class'
export const SERVICE_SLUG_SMS_TEXT = 'sms_text'

export const PRIVATE_LABEL_PAGE_TYPES = {
    LANDING_PAGE: 'LANDING_PAGE',
    ACTIVATION_PAGE: 'ACTIVATION_PAGE',
    BALANCE_PAGE: 'BALANCE_PAGE',
    DETAILS_PAGE: 'DETAILS_PAGE',
    REDEMPTION_PAGE: 'REDEMPTION_PAGE',
}

export const CARD_TO_CARD_ACTION_TYPE_CARD_LINKED: string = 'card_linked'
export const CARD_TO_CARD_ACTION_TYPE_MERCHANT_CHANGED: string =
    'merchant_changed'
export const CARD_TO_CARD_ACTION_TYPE_EGIFT_CREATED: string = 'egift_created'
export const CARD_TO_CARD_ACTION_TYPE_VISA_CARD_CREATED: string =
    'visa_card_created'
export const CARD_TO_CARD_ACTION_TYPE_VENMO: string = 'venmo_cashout'
export const CARD_TO_CARD_ACTION_TYPE_PAYPAL: string = 'paypal_cashout'
export const CARD_TO_CARD_ACTION_TYPE_ZELLE: string = 'zelle_cashout'
export const CARD_TO_CARD_ACTION_TYPE_REGIFTED: string = 'regifted'

export const LINK_STATUS_SUCCESS: string = 'success'
export const LINK_STATUS_PENDING: string = 'pending'
export const LINK_STATUS_FAILURE: string = 'failure'

export const BRAND_PG_PLUS: string = 'PG+'
export const PG_PLUS_SLUG: string = StringHelpers.slugify(BRAND_PG_PLUS)
export const PG_PLUS_NAME: string = 'PerfectGift+'

// This array is used to indicate which redemption methods are to be considered
// when looking for the latest state of a gift.
export const REDEMPTION_ACTIONS = [
    CARD_TO_CARD_ACTION_TYPE_CARD_LINKED,
    CARD_TO_CARD_ACTION_TYPE_EGIFT_CREATED,
    CARD_TO_CARD_ACTION_TYPE_VISA_CARD_CREATED,
    CARD_TO_CARD_ACTION_TYPE_MERCHANT_CHANGED, // This IS considered a "redemption action".
    CARD_TO_CARD_ACTION_TYPE_ZELLE,
    CARD_TO_CARD_ACTION_TYPE_REGIFTED,
]
export const TERMINAL_METHODS = [
    CARD_TO_CARD_ACTION_TYPE_EGIFT_CREATED,
    CARD_TO_CARD_ACTION_TYPE_VISA_CARD_CREATED,
    CARD_TO_CARD_ACTION_TYPE_ZELLE,
    CARD_TO_CARD_ACTION_TYPE_REGIFTED,
]

export enum STRIPE_NETWORK_TYPES {
    AMAZON_PAY = 'amazon_pay',
    VISA = 'visa',
}

export enum PAYMENT_PROCESSOR {
    STRIPE = 'stripe',
    PAYPAL = 'paypal',
    NO_PAYMENT = 'no_payment', // regift orders
}

export enum FEATURE_FLAGS {
    HANDWRITTEN = 'handwritten',
    OPEN_LOOP_REQUIRES_RECIPIENT_LOGIN = 'OPEN_LOOP_REQUIRES_RECIPIENT_LOGIN',
    THREAT_LEVEL = 'THREAT_LEVEL',
    PAYPAL_BUTTON = 'PAYPAL_BUTTON',
    RISKIFIED_REVIEW_MODE = 'RISKIFIED_REVIEW_MODE',
}

export const CARD_TO_CARD_ACTION_STATUS_PENDING: string = 'pending'
export const CARD_TO_CARD_ACTION_STATUS_PROCESSED: string = 'processed'
export const CARD_TO_CARD_ACTION_STATUS_REMOVED: string = 'removed'
export const CARD_TO_CARD_ACTION_STATUS_FAILED: string = 'failed'
export const CARD_TO_CARD_ACTION_STATUSES: string[] = [
    CARD_TO_CARD_ACTION_STATUS_PENDING,
    CARD_TO_CARD_ACTION_STATUS_PROCESSED,
    CARD_TO_CARD_ACTION_STATUS_FAILED,
]

export const CARD_TO_CARD_EVENT_TYPE_GIFT_CREDIT_FAILED: string =
    'gift_credit_failed'
export const CARD_TO_CARD_EVENT_TYPE_CARD_ENROLL_FAILURE: string =
    'card_enroll_failure'
export const CARD_TO_CARD_EVENT_TYPE_CARD_LINK_TRANSACTION_CREATED: string =
    'card_link_transaction_created'
export const CARD_TO_CARD_EVENT_TYPE_CARD_LINK_TRANSACTION_CREDIT_SUCCESS: string =
    'card_link_transaction_credit_success'
export const CARD_TO_CARD_EVENT_TYPE_CARD_LINKED: string = 'card_linked'
export const CARD_TO_CARD_EVENT_TYPE_CARD_UNLINKED: string = 'card_unlinked'
export const CARD_TO_CARD_EVENT_TYPE_CARD_UNENROLLED: string = 'card_unenrolled'
export const CARD_TO_CARD_EVENT_TYPE_EGIFT_EXCHANGED: string = 'egift_exchanged'
export const CARD_TO_CARD_EVENT_TYPE_GIFT_DELIVERED_VIA_EMAIL: string =
    'gift_delivered_via_email'
export const CARD_TO_CARD_EVENT_TYPE_GIFT_DELIVERED_VIA_SMS: string =
    'gift_delivered_via_sms'
export const CARD_TO_CARD_EVENT_TYPE_GIFT_RECEIVED: string = 'gift_received'
export const CARD_TO_CARD_EVENT_TYPE_MERCHANT_CHANGED: string =
    'merchant_changed'
export const CARD_TO_CARD_EVENT_TYPE_MISSING_CREDIT_CREDIT_SUCCESS: string =
    'missing_credit_credit_success'
export const CARD_TO_CARD_EVENT_TYPE_PLASTIC_VISA_REQUESTED: string =
    'plastic_visa_requested'
export const CARD_TO_CARD_EVENT_TYPE_PLASTIC_VISA_EXCHANGED: string =
    'plastic_visa_exchanged'
export const CARD_TO_CARD_EVENT_TYPE_ACCOUNT_LINK_SUCCESS: string =
    'account_link_success'
export const CARD_TO_CARD_EVENT_TYPE_ACCOUNT_LINK_FAILURE: string =
    'account_link_failure'
export const CARD_TO_CARD_EVENT_TYPE_RESENT_REDEMPTION: string =
    'resent_redemption'
export const CARD_TO_CARD_EVENT_TYPE_UNLINK_RECIPIENT: string =
    'unlink_recipient'

export const CARD_TO_CARD_EVENT_TYPE_REGIFT_SUCCESS: string = 'regift_success'
export const CARD_TO_CARD_EVENT_TYPE_REGIFT_IN_PROGRESS: string =
    'regift_in_progress'

/**
 * review, approved & declined are all fraud review statuses, the rest are
 * statuses used while attempting to process the Zelle transaction through PNC
 * processed means we successfully sent it to PNC
 * verified means we got confirmation back from PNC that it processed on their side
 */
export const CARD_TO_CARD_EVENT_TYPE_ZELLE_REDEMPTION_IN_REVIEW =
    'gift_zelle_redemption_in_review' as const
export const CARD_TO_CARD_EVENT_TYPE_ZELLE_REDEMPTION_APPROVED =
    'gift_zelle_redemption_approved' as const
export const CARD_TO_CARD_EVENT_TYPE_ZELLE_REDEMPTION_DECLINED =
    'gift_zelle_redemption_declined' as const
export const CARD_TO_CARD_EVENT_TYPE_ZELLE_REDEMPTION_SUBMITTED =
    'gift_zelle_redemption_submitted_to_pnc' as const
export const CARD_TO_CARD_EVENT_TYPE_ZELLE_REDEMPTION_VERIFIED =
    'gift_zelle_redemption_verified_by_pnc' as const
export const CARD_TO_CARD_EVENT_TYPE_ZELLE_REDEMPTION_PROCESSED =
    'gift_zelle_redemption_processed_by_pnc' as const
export const CARD_TO_CARD_EVENT_TYPE_ZELLE_REDEMPTION_FAILED =
    'gift_zelle_redemption_failed_at_pnc' as const

export const LOWES_EXTERNAL_ID: string = '90249251-03e1-422c-b231-6c235229ce06'

export const PHYSICAL_SMB_PROGRAM_NAME =
    'pg_consumer_incentive_visa_physical_1000_12_months_qolo'
