import NextLink from 'next/link'
import { theme } from '@/src/stitches.config'
import { Text } from '@/src/common/components/elements/Text/Text'
import StarRating from '@/src/common/components/marketing/reviews/StarRating'
import {
    SALES_PHONE_NUMBER_DATA_ID,
    SALES_PHONE_NUMBER_FORMATTED,
    SALES_PHONE_NUMBER_TEL,
    SUPPORT_PHONE_NUMBER_FORMATTED,
    SUPPORT_PHONE_NUMBER_TEL,
} from '@/src/common/components/marketing/text/PhoneNumbers/strings'
import { SocialShareIcons } from '@/src/common/components/marketing/buttons/SocialShareIcons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import {
    FooterWrapper,
    FooterTop,
    SectionList,
    SectionListItem,
    SectionListItemContent,
    SectionListItemInnerContent,
    LinkHeaderContent,
    PhoneNumberWithTextWrapper,
    RatingWrapper,
    StyledLink,
    styles,
} from '@/src/modules/footer/StyledComponents'
import ConsumerAndCorporateFooterLegal from './ConsumerAndCorporateFooterLegal'
import { COBRAND_BAC_PAGE } from '@/src/modules/pg-plus/constants'

interface IFooterProps {
    isCorporate: boolean
    disableFooterTop: boolean
    customDisclaimer?: string
}

export const ConsumerAndCorporateFooter = ({
    isCorporate,
    disableFooterTop,
    customDisclaimer,
}: IFooterProps) => {
    const [isOpen, setIsOpen] = useState<boolean[]>([
        false,
        true,
        false,
        false,
        false,
        false,
    ])

    const toggleDropdown = (index: number) => {
        const oldIsOpen = [...isOpen]
        oldIsOpen[index] = !oldIsOpen[index]
        setIsOpen(oldIsOpen)
    }

    return (
        <FooterWrapper>
            {!disableFooterTop && (
                <>
                    <FooterTop>
                        <SectionList>
                            <SectionListItem>
                                <SectionListItemContent>
                                    <LinkHeaderContent
                                        onClick={() => toggleDropdown(0)}
                                    >
                                        PerfectGift, LLC
                                        <FontAwesomeIcon
                                            icon={faAngleUp}
                                            className={isOpen[0] ? 'down' : ''}
                                        />
                                    </LinkHeaderContent>
                                    <SectionListItemInnerContent
                                        isOpen={isOpen[0]}
                                    >
                                        <div className={'pusher'}></div>
                                        <Text size={2}>
                                            495 Mansfield Avenue
                                        </Text>
                                        <Text size={2}>
                                            Pittsburgh, PA 15205
                                        </Text>
                                        <PhoneNumberWithTextWrapper>
                                            Sales:{' '}
                                            <a
                                                data-test-id={
                                                    'footerSalesPhoneNumber'
                                                }
                                                data-phone={
                                                    SALES_PHONE_NUMBER_DATA_ID
                                                }
                                                href={SALES_PHONE_NUMBER_TEL}
                                                role={'button'}
                                            >
                                                {SALES_PHONE_NUMBER_FORMATTED}
                                            </a>
                                        </PhoneNumberWithTextWrapper>
                                    </SectionListItemInnerContent>
                                </SectionListItemContent>
                                <SectionListItemContent
                                    style={styles.marginTop}
                                >
                                    <LinkHeaderContent
                                        onClick={() => toggleDropdown(1)}
                                    >
                                        Follow us
                                        <FontAwesomeIcon
                                            icon={faAngleUp}
                                            className={isOpen[1] ? 'down' : ''}
                                        />
                                    </LinkHeaderContent>
                                    <SectionListItemInnerContent
                                        isOpen={isOpen[1]}
                                    >
                                        <div className={'pusher'}></div>
                                        <SocialShareIcons
                                            facebookUrl={
                                                'https://www.facebook.com/PerfectGiftCom'
                                            }
                                            twitterUrl={
                                                'https://twitter.com/PerfectGiftcom'
                                            }
                                            youtubeUrl={
                                                'https://www.youtube.com/channel/UCYAuggt2bUIdUokfLiv9piA'
                                            }
                                            instagramUrl={
                                                'https://www.instagram.com/perfectgiftcom/'
                                            }
                                            linkedinUrl={
                                                'https://www.linkedin.com/company/perfectgiftcom/'
                                            }
                                            iconColor={'white'}
                                        />
                                        <RatingWrapper>
                                            <NextLink
                                                href={'/reviews'}
                                                passHref
                                                legacyBehavior
                                            >
                                                <StyledLink>
                                                    Customer reviews
                                                </StyledLink>
                                            </NextLink>
                                            <StarRating
                                                ratingValue={5}
                                                fillColor={`${theme.colors.warning}`}
                                                starSize={16}
                                            />
                                        </RatingWrapper>
                                        <NextLink
                                            href={'/corporate/testimonials'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Corporate client testimonials
                                            </StyledLink>
                                        </NextLink>
                                    </SectionListItemInnerContent>
                                </SectionListItemContent>
                            </SectionListItem>
                            <SectionListItem>
                                <SectionListItemContent>
                                    <LinkHeaderContent
                                        onClick={() => toggleDropdown(2)}
                                    >
                                        Gifting
                                        <FontAwesomeIcon
                                            icon={faAngleUp}
                                            className={isOpen[2] ? 'down' : ''}
                                        />
                                    </LinkHeaderContent>
                                    <SectionListItemInnerContent
                                        isOpen={isOpen[2]}
                                    >
                                        <div className={'pusher'}></div>
                                        <NextLink
                                            href={
                                                isCorporate
                                                    ? '/corporate/visa'
                                                    : '/visa-gift-cards'
                                            }
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Visa Gift Cards
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/brands'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>Brands</StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/custom-greeting-cards'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                &apos;New&apos; Handwritten
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/gift-guides'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>Gift Guides</StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/corporate'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>Corporate</StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={COBRAND_BAC_PAGE}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Small Business Rewards
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/sales-materials'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Sales Materials
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/corporate/cobrand'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Custom Solutions
                                            </StyledLink>
                                        </NextLink>
                                        <StyledLink
                                            data-test-id={
                                                'footer-manufacturing-video'
                                            }
                                            href={
                                                'https://www.youtube.com/watch?v=52erIkJrT4s'
                                            }
                                            target={'_blank'}
                                            rel={'noopener noreferrer'}
                                        >
                                            Manufacturing &amp; Fulfillment
                                            Video
                                        </StyledLink>
                                    </SectionListItemInnerContent>
                                </SectionListItemContent>
                            </SectionListItem>
                            <SectionListItem>
                                <SectionListItemContent>
                                    <LinkHeaderContent
                                        onClick={() => toggleDropdown(3)}
                                    >
                                        Company
                                        <FontAwesomeIcon
                                            icon={faAngleUp}
                                            className={isOpen[3] ? 'down' : ''}
                                        />
                                    </LinkHeaderContent>
                                    <SectionListItemInnerContent
                                        isOpen={isOpen[3]}
                                    >
                                        <div className={'pusher'}></div>
                                        <NextLink
                                            href={'/about-us'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>About</StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/press-media'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Press &amp; Media
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/careers'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>Careers</StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/pricing'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Consumer Pricing
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/corporate/pricing'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Corporate Pricing
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/corporate/signup'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Set up your account &amp; order
                                                today!
                                            </StyledLink>
                                        </NextLink>
                                        <StyledLink
                                            rel="noopener"
                                            target="_blank"
                                            href="https://groups.giveinkind.com/employers/"
                                        >
                                            Support Your Team with Give InKind
                                        </StyledLink>
                                    </SectionListItemInnerContent>
                                </SectionListItemContent>
                            </SectionListItem>
                            <SectionListItem>
                                <SectionListItemContent>
                                    <LinkHeaderContent
                                        onClick={() => toggleDropdown(4)}
                                    >
                                        Partner with us
                                        <FontAwesomeIcon
                                            icon={faAngleUp}
                                            className={isOpen[4] ? 'down' : ''}
                                        />
                                    </LinkHeaderContent>
                                    <SectionListItemInnerContent
                                        isOpen={isOpen[4]}
                                    >
                                        <div className={'pusher'}></div>
                                        <NextLink
                                            href={'/corporate/api'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>APIs</StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={
                                                '/corporate/business-development'
                                            }
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Business development
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/corporate/products'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Business products
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/corporate/solutions'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Rewards solutions
                                            </StyledLink>
                                        </NextLink>
                                    </SectionListItemInnerContent>
                                </SectionListItemContent>
                            </SectionListItem>
                            <SectionListItem>
                                <SectionListItemContent>
                                    <LinkHeaderContent
                                        onClick={() => toggleDropdown(5)}
                                    >
                                        24/7 Support
                                        <FontAwesomeIcon
                                            icon={faAngleUp}
                                            className={isOpen[5] ? 'down' : ''}
                                        />
                                    </LinkHeaderContent>
                                    <SectionListItemInnerContent
                                        isOpen={isOpen[5]}
                                    >
                                        <div className={'pusher'}></div>
                                        <PhoneNumberWithTextWrapper>
                                            Support:{' '}
                                            <a
                                                role={'button'}
                                                data-test-id={
                                                    'footerSupportPhoneNumber'
                                                }
                                                href={SUPPORT_PHONE_NUMBER_TEL}
                                            >
                                                {SUPPORT_PHONE_NUMBER_FORMATTED}
                                            </a>
                                        </PhoneNumberWithTextWrapper>
                                        <NextLink
                                            href={'/activate'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>Activate</StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/check-balance'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Check balance
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={'/order-status'}
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>
                                                Track your order
                                            </StyledLink>
                                        </NextLink>
                                        <NextLink
                                            href={
                                                'https://perfectgiftsupport.zendesk.com/hc/en-us'
                                            }
                                            passHref
                                            legacyBehavior
                                        >
                                            <StyledLink>FAQs</StyledLink>
                                        </NextLink>
                                        <StyledLink
                                            rel="noopener"
                                            target="_blank"
                                            href="https://perfectgiftsupport.zendesk.com/hc/en-us"
                                        >
                                            Help center
                                        </StyledLink>
                                    </SectionListItemInnerContent>
                                </SectionListItemContent>
                            </SectionListItem>
                        </SectionList>
                    </FooterTop>
                </>
            )}
            <ConsumerAndCorporateFooterLegal
                customDisclaimer={customDisclaimer}
            />
        </FooterWrapper>
    )
}
export default ConsumerAndCorporateFooter
