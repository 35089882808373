import { firebaseAuth } from '@/config/firebaseClientConfig'
import {
    createUserWithEmailAndPassword,
    deleteUser as authDeleteUser,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    OAuthProvider,
    sendPasswordResetEmail,
    signInWithCredential,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut as authSignOut,
    UserCredential,
} from 'firebase/auth'
import { loadFacebookSDK } from './loadFacebookSDK'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { isNativeApp } from '@/src/common/deviceInfo'

const signOut = async () => {
    await authSignOut(firebaseAuth)
    if (isNativeApp) {
        await FirebaseAuthentication.signOut()
    }
}

async function deleteUser({
    setIsLoading,
    setError,
}: {
    setIsLoading: Function
    setError: Function
}) {
    const auth = getAuth()
    const user = auth?.currentUser

    if (user) {
        setIsLoading(true)
        authDeleteUser(user)
            .then(() => {
                setError(null)
            })
            .catch(() => {
                setError(
                    'An error occurred while deleting your account. Please try again.'
                )
            })
            .finally(() => setIsLoading(false))
        return
    }

    setError('No user found. Please try again or refresh the page.')
}

const googleSignIn = async () => {
    const provider = new GoogleAuthProvider()
    // provider.addScope('... additional user data to receive with login ...')
    if (isNativeApp) {
        try {
            const result = await FirebaseAuthentication.signInWithGoogle()
            const credential = GoogleAuthProvider.credential(
                result.credential?.idToken
            )
            const userCredential = await signInWithCredential(
                getAuth(),
                credential
            )
            return {
                user: userCredential?.user,
                accessToken: credential?.accessToken,
            }
        } catch (error) {
            console.error('Google sign in failed', error)
            return { error }
        }
    } else {
        try {
            const res = await signInWithPopup(getAuth(), provider)
            const credential = GoogleAuthProvider.credentialFromResult(res)
            return { user: res?.user, accessToken: credential?.accessToken }
        } catch (e: any) {
            if (e?.code !== 'auth/popup-closed-by-user') {
                console.log('Google signin popup closed')
            }
            return { error: e }
        }
    }
}

const handleFacebookAuth = async (accessToken: string) => {
    try {
        const credential = FacebookAuthProvider.credential(accessToken)
        const res = await signInWithCredential(firebaseAuth, credential)
        return { user: res?.user, accessToken: credential?.accessToken }
    } catch (error) {
        console.error('Firebase Sign-In Error:', error)
    }
}

/**
 * We are using the facebook login SDK manually instead of firebase due to issues
 * with the popup authentication not completing
 */
const facebookSignIn = async () => {
    try {
        await loadFacebookSDK()

        if (isNativeApp) {
            try {
                const result = await FirebaseAuthentication.signInWithFacebook()
                const credential = FacebookAuthProvider.credential(
                    result.credential?.accessToken as string
                )
                await signInWithCredential(getAuth(), credential)
            } catch (error) {
                console.error('Error signing in with Firebase:', error)
                throw error
            }
        } else {
            return new Promise((resolve, reject) => {
                // FB.login() expects a synchronous function
                window.FB.login(
                    (response: any) => {
                        if (response.authResponse) {
                            handleFacebookAuth(
                                response.authResponse.accessToken
                            )
                                .then((res) => {
                                    resolve(res)
                                })
                                .catch((error) => {
                                    console.error(error)
                                    reject(error)
                                })
                        } else {
                            const error =
                                'User cancelled login or did not fully authorize.'
                            console.error(error)
                            reject(new Error(error))
                        }
                    },
                    { scope: 'public_profile,email' }
                )
            })
        }
    } catch (error) {
        console.error('Facebook Sign-In Error:', error)
        return { error }
    }
}

const appleSignIn = async () => {
    const provider = new OAuthProvider('apple.com')
    if (isNativeApp) {
        try {
            const result = await FirebaseAuthentication.signInWithApple({
                skipNativeAuth: true,
            })
            const credential = provider.credential({
                idToken: result.credential?.idToken,
                rawNonce: result.credential?.nonce,
            })
            await signInWithCredential(getAuth(), credential)
        } catch (error) {
            console.error('Apple sign in failed', error)
        }
    } else {
        try {
            const res = await signInWithPopup(getAuth(), provider)
            const credential = OAuthProvider.credentialFromResult(res)
            return { user: res?.user, accessToken: credential?.accessToken }
        } catch (e: any) {
            if (e?.code !== 'auth/popup-closed-by-user') {
                console.log('Apple signin popup closed')
            }
        }
    }
}

const emailSignUp = async ({
    email,
    password,
}: {
    email: string
    password: string
}) => {
    try {
        const userCredential: UserCredential =
            await createUserWithEmailAndPassword(firebaseAuth, email, password)

        return { userCredential }
    } catch (e: any) {
        if (e?.code == 'auth/email-already-in-use') {
            return {
                error: {
                    name: '',
                    path: 'email',
                    message: 'Email address already in use.',
                    value: 'email already in use',
                    inner: [],
                    errors: [],
                },
            }
        }

        if (e?.code == 'auth/too-many-requests') {
            return {
                error: {
                    name: '',
                    path: 'password',
                    message:
                        'Too many failed attempts. Please try again later.',
                    value: 'too many failed attempts',
                    inner: [],
                    errors: [],
                },
            }
        }

        return {
            error: {
                name: '',
                path: 'password',
                message:
                    'Something went wrong. Please verify your credentials and try again.',
                value: 'general error',
                inner: [],
                errors: [],
            },
        }
    }
}

const emailSignIn = async ({
    email,
    password,
}: {
    email: string
    password: string
}) => {
    try {
        await signInWithEmailAndPassword(firebaseAuth, email, password)
    } catch (e: any) {
        if (e?.code === 'auth/invalid-credential') {
            return {
                error: {
                    name: '',
                    path: 'password',
                    message: 'Email address or password is invalid',
                    value: 'Incorrect email or password',
                    inner: [],
                    errors: [],
                },
            }
        }

        if (e?.code === 'auth/wrong-password') {
            return {
                error: {
                    name: '',
                    path: 'password',
                    message: 'Incorrect password',
                    value: 'Incorrect password',
                    inner: [],
                    errors: [],
                },
            }
        }

        if (e?.code === 'auth/user-not-found') {
            return {
                error: {
                    name: '',
                    path: 'email',
                    message: 'No account with that email.',
                    value: 'No account with that email',
                    inner: [],
                    errors: [],
                },
            }
        }

        if (e?.code == 'auth/too-many-requests') {
            return {
                error: {
                    name: '',
                    path: 'password',
                    message:
                        'Too many failed attempts. Please try again later.',
                    value: 'too many failed attempts',
                    inner: [],
                    errors: [],
                },
            }
        }

        return {
            error: {
                name: '',
                path: 'password',
                message:
                    'Something went wrong. Please verify your credentials and try again.',
                value: 'general error',
                inner: [],
                errors: [],
            },
        }
    }
}

const resetPassword = async (email: string) => {
    try {
        const auth = getAuth()
        const res = await sendPasswordResetEmail(auth, email)

        return { success: true, data: res }
    } catch (e) {
        return { success: false, data: e }
    }
}

export {
    signOut,
    googleSignIn,
    facebookSignIn,
    appleSignIn,
    emailSignIn,
    emailSignUp,
    resetPassword,
    deleteUser,
}
