import {
    AlertText,
    AlertTextWrapper,
    AlertWrapper,
} from '@/src/common/components/elements/Alerts/StyledComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { MAX_CARDS, MAX_PURCHASE_AMOUNT } from '@/src/modules/checkout/strings'

const PurchaseFlowMaxAlert = ({
    isLogin = false,
    style = {},
    hitsWeeklyLimit,
    hasSMBCard = false,
    size = 'md',
    isFlyout = false,
}: {
    isLogin?: boolean
    style?: object
    hitsWeeklyLimit?: boolean
    hasSMBCard?: boolean
    size?: 'sm' | 'md'
    isFlyout?: boolean
}) => {
    const actionText: string = isLogin
        ? 'Please login or sign up for a PerfectGift account so we can accurately process your order.'
        : 'This order may require additional steps in the checkout process.'
    const orderText: string = hitsWeeklyLimit ? 'weekly order history' : 'order'

    if (hasSMBCard) {
        const message = isFlyout ? (
            'Reward cards require an account and business application. Checkout may require additional steps.'
        ) : (
            <>
                Attention! To purchase Reward cards, you must have a
                PerfectGift.com account and business application.{' '}
                {isLogin ? 'Please login or sign up to continue.' : ''}
            </>
        )

        return (
            <AlertWrapper variant={'warning'} style={style} size={size}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <AlertTextWrapper>
                    <AlertText>{message}</AlertText>
                </AlertTextWrapper>
            </AlertWrapper>
        )
    }

    return (
        <AlertWrapper variant={'warning'} style={style} size={size}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <AlertTextWrapper>
                <AlertText>
                    Attention! Your {orderText} has a gift quantity greater than{' '}
                    {MAX_CARDS} or an order value of more than $
                    {MAX_PURCHASE_AMOUNT}. {actionText}
                </AlertText>
            </AlertTextWrapper>
        </AlertWrapper>
    )
}

export default PurchaseFlowMaxAlert
