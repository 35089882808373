import { useEffect } from 'react'
import { keyframes, styled } from '@/src/stitches.config'
import { blackA } from '@radix-ui/colors'
import * as DialogPrimitive from '@radix-ui/react-dialog'

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
})

const contentShow = keyframes({
    '0%': { transform: 'translate(-50%, -49%)' },
    '100%': { transform: 'translate(-50%, -50%)' },
})

const StyledOverlay = styled(DialogPrimitive.Overlay, {
    zIndex: 2,
    backgroundColor: blackA.blackA9,
    position: 'fixed',
    inset: 0,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
        willChange: 'opacity',
    },
})

const StyledContent = styled(DialogPrimitive.Content, {
    height: 'fit-content',
    maxHeight: '90vh',
    width: '95vw',
    maxWidth: '95vw',
    zIndex: 3,
    backgroundColor: 'white',
    boxShadow:
        'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 25,
    overflowY: 'auto',
    borderRadius: 12,
    animation: 'unset',
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
        willChange: 'transform',
    },
    '&:focus': { outline: 'none' },
    variants: {
        width: {
            'extra-small': {
                '@media (min-width: 426px)': {
                    width: 360,
                },
            },
            small: {
                '@media (min-width: 426px)': {
                    width: 450,
                },
            },
            'small-medium': {
                '@media (min-width: 426px)': {
                    width: 560,
                },
            },
            medium: {
                '@media (min-width: 426px)': {
                    width: 900,
                },
            },
            large: {
                '@media (min-width: 426px)': {
                    width: 1280,
                },
            },
        },
        height: {
            'min-content': {
                '@media (min-width: 426px)': {
                    height: 'min-content',
                },
            },
            '70vh': {
                '@media (min-width: 426px)': {
                    height: '70vh',
                },
            },
            '90vh': {
                '@media (min-width: 426px)': {
                    height: '90vh',
                },
            },
            '550px': {
                '@media (min-width: 426px)': {
                    height: 550,
                },
            },
        },
    },
    defaultVariant: {
        width: 'small',
        height: 'min-content',
    },
})

// @ts-ignore
function Content({ children, ...props }) {
    // radix adds padding right to body in an attempt to handle screen scroll
    // this makes it so every time radix tries to add that, it is removed
    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'data-scroll-locked') {
                    document.body.removeAttribute('data-scroll-locked')
                }
            })
        })

        observer.observe(document.body, { attributes: true })

        return () => observer.disconnect()
    }, [])

    return (
        <DialogPrimitive.Portal>
            <StyledOverlay />
            <StyledContent {...props}>{children}</StyledContent>
        </DialogPrimitive.Portal>
    )
}

const StyledTitle = styled(DialogPrimitive.Title, {
    margin: 0,
    fontWeight: 500,
    color: '$primary',
    fontSize: 17,
})

const StyledDescription = styled(DialogPrimitive.Description, {
    margin: '10px 0 20px',
    color: '$dark',
    fontSize: 15,
    lineHeight: 1.5,
})

// Exports
export const Dialog = DialogPrimitive.Root
export const DialogContent = Content
export const DialogTitle = StyledTitle
export const DialogDescription = StyledDescription
export const DialogClose = DialogPrimitive.Close
