import {
    PG_CART_SESSION_UUID,
    PROMO_CODE,
    WL_CART_SESSION_UUID,
} from '@/config/cookieNames'
import { PARTNER_DATA_COOKIE, PARTNER_SLUG } from '@/src/common/constants'
import useGetCartManually from '@/src/common/hooks/useGetCartManually'
import useSessionStorage from '@/src/common/hooks/useSessionStorage'
import { useSyncCookies } from '@/src/common/hooks/useSyncCookies'
import cardLimitValidator from '@/src/common/utilities/cardLimitValidator'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { ICart } from '@/src/modules/cart/ICart'
import { SESSION_PURCHASER_NAME } from '@/src/modules/checkout/strings'
import { TOrderLimitDetails } from '@/src/modules/kyc/types'
import { determineIfInValidPartnerFlow } from '@/src/modules/partners/utilities/utilities'
import { useRouter } from 'next/router'
import {
    createContext,
    Dispatch,
    MutableRefObject,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useCookies } from 'react-cookie'
import {
    determineIfConsumerOrderIsOverWeeklyLimit,
    determineIfKYCIsOverWeeklyLimit,
} from '../utilities/kycHelpers'
import { determineIfHasSMBCard } from '@/src/modules/cart/utilities'

type TCartPropertiesState = {
    cartData: ICart | undefined
    setCartData: Function
    cartTotalCards: number
    cartTotalLoad: number
    cartFlyoutOpen: boolean
    setCartFlyoutOpen: Function
    promoCode: string
    orderLimitDetails: TOrderLimitDetails
    setOrderLimitDetails: (details: TOrderLimitDetails) => void
    cartSessionUuid: string
    purchaserEmail: MutableRefObject<string>
    purchaserPhone: MutableRefObject<string>
    storedName: any
    setStoredName: Dispatch<SetStateAction<any>>
    hasSMBCard: boolean
}

export const CartContext = createContext<TCartPropertiesState | null>(null)

export const useCartContext = (): TCartPropertiesState => {
    const context = useContext(CartContext)

    if (!context) {
        throw new Error('Please use CartContext in parent component')
    }

    return context
}

export const CartProvider = ({ children }: { children: ReactNode }) => {
    const { user } = useUser()
    const { clearWhiteLabelCart, clearPartnerData, clearPartnerSlug } =
        useSyncCookies()

    const [cartData, setCartData] = useState<ICart>()
    const [cartFlyoutOpen, setCartFlyoutOpen] = useState<boolean>(false)
    const router = useRouter()
    const [storedName, setStoredName] = useSessionStorage<string>(
        SESSION_PURCHASER_NAME,
        ''
    )
    // determine if cart has cobrand in it
    const hasSMBCard: boolean = determineIfHasSMBCard(
        cartData?.cart_contents?.deliveries ?? []
    )

    const purchaserEmail = useRef<string>('')
    const purchaserPhone = useRef<string>('')

    useEffect(() => {
        if (user?.email && !purchaserEmail.current) {
            purchaserEmail.current = user.email
        }
        if (user?.phoneNumber && !purchaserPhone.current) {
            purchaserPhone.current = user.phoneNumber
        }

        // if purchaser name not set, and display name or stored name is available
        // set purchaser name in ref and session storage (for refresh purposes)
        if (user?.displayName && !storedName) {
            setStoredName(user.displayName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // determining current total load and number of cards in cart
    const { total, numberOfCards } = cardLimitValidator({
        cartData: cartData,
        isEditing: false,
        quantityToEditFromCart: 0,
    })

    const cartTotalCards = useMemo(() => {
        return cartData?.resolved_data?.items_count ?? total
    }, [cartData?.resolved_data?.items_count, total])
    const cartTotalLoad = useMemo(() => {
        return parseFloat(
            cartData?.resolved_data?.load_value_total ?? `${numberOfCards}`
        )
    }, [cartData?.resolved_data?.load_value_total, numberOfCards])
    // END

    const [cookies, setCookie] = useCookies([
        PARTNER_DATA_COOKIE,
        PARTNER_SLUG,
        WL_CART_SESSION_UUID,
        PROMO_CODE,
        PG_CART_SESSION_UUID,
    ])

    const urlPath = router.asPath
    const { hasPartner, isInvalidPathForPartner, useWLCart } =
        determineIfInValidPartnerFlow(router, cookies)

    // handles auto-setting promo codes
    const promoCode = useMemo(() => {
        return cookies[PROMO_CODE]
    }, [cookies])

    const cartSessionUuid =
        cookies[useWLCart ? WL_CART_SESSION_UUID : PG_CART_SESSION_UUID]

    const [orderLimitDetails, setOrderLimitDetails] =
        useState<TOrderLimitDetails>({
            requiresKyc: false,
            overage: { amount: 0, type: undefined, code: undefined },
            KYCIsOverWeeklyLimit: false,
            consumerOrderIsOverWeeklyLimit: false,
        })

    const { overage } = orderLimitDetails
    useEffect(() => {
        const consumerIsOverLimit = determineIfConsumerOrderIsOverWeeklyLimit(
            overage.code
        )

        setOrderLimitDetails({
            ...orderLimitDetails,
            KYCIsOverWeeklyLimit: determineIfKYCIsOverWeeklyLimit(overage.code),
            consumerOrderIsOverWeeklyLimit: consumerIsOverLimit,
            requiresKyc:
                consumerIsOverLimit ||
                (cartData?.resolved_data?.requires_kyc_application ?? false),
        })
    }, [cartData?.resolved_data?.requires_kyc_application, overage.code])

    useEffect(() => {
        // if user has stored partner data and is outside of partner flow, remove cookies
        if (hasPartner && isInvalidPathForPartner) {
            clearPartnerData()
            clearPartnerSlug()
            clearWhiteLabelCart()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlPath])

    // handle resetting cart data between main site and white label
    const { refetch: refetchCart, data: refetchData } = useGetCartManually()

    // handles fetching the latest cart update
    useEffect(() => {
        refetchCart().then()
    }, [refetchCart, useWLCart])

    // update cart data if new available
    useEffect(() => {
        setCartData(refetchData as ICart)
    }, [refetchData, setCartData])

    const providerValue = useMemo(
        () => ({
            cartTotalCards,
            cartTotalLoad,
            cartFlyoutOpen,
            promoCode,
            setCartFlyoutOpen,
            cartData,
            cartSessionUuid,
            orderLimitDetails,
            purchaserEmail,
            purchaserPhone,
            storedName,
            setCartData,
            setOrderLimitDetails,
            setStoredName,
            hasSMBCard,
        }),
        [
            cartTotalCards,
            cartTotalLoad,
            cartFlyoutOpen,
            promoCode,
            cartData,
            cartSessionUuid,
            orderLimitDetails,
            purchaserEmail,
            purchaserPhone,
            storedName,
            setCartFlyoutOpen,
            setCartData,
            setOrderLimitDetails,
            setStoredName,
            hasSMBCard,
        ]
    )
    return (
        <CartContext.Provider value={providerValue}>
            {children}
        </CartContext.Provider>
    )
}
