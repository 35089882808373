import {
    APP_TYPE,
    BRICKYARD_MS_QUERY_PARAM,
    PARTNER_DATA_COOKIE,
    UTM_CAMPAIGN,
    UTM_ID,
    UTM_MEDIUM,
    UTM_SOURCE,
} from '@/src/common/constants'
import { GA_TRACKING_ID, pageview } from '@/src/common/utilities/gtag'
import { AuthProvider } from '@/src/modules/auth/context/AuthProvider'
import { Builder, builder } from '@builder.io/react'
import '@fontsource/shadows-into-light/400.css'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css' // import Font Awesome CSS
import * as FullStory from '@fullstory/browser'
import { SpeedInsights } from '@vercel/speed-insights/next'
import get from 'lodash/get'
import type { AppProps } from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import { Router } from 'next/router'
import Script from 'next/script'
import React, { useEffect, useState } from 'react'
import { CookiesProvider, useCookies } from 'react-cookie'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { DATADOME_JS, DATADOME_TAGS } from '@/datadome/constants'
import GlobalLayout from '../src/common/components/elements/Layout/GlobalLayout'
import PrivateLabelPartnerLayout from '../src/common/components/elements/Layout/PrivateLabelPartnerLayout'
import '../styles/fonts.css'
import '../styles/globals.css'
import { BRICKYARD_MS_BUYER, RISKIFIED_SESSION_ID } from '@/config/cookieNames'
import { useIdleTimer } from 'react-idle-timer'
import {
    handleAdaCampaign,
    isExcludedPage,
} from '@/src/modules/chat-bot/utilities'
import { v4 as uuidv4 } from 'uuid'
import { isNativeApp } from '@/src/common/deviceInfo'
import { IsNativeProvider } from '@/src/common/contexts/IsNativeContext'
import AppLayout from '@/src/common/components/elements/Layout/AppLayout'
import { KeyboardProvider } from '@/src/common/contexts/KeyboardContext'

import { theme as defaultTheme } from '@/src/stitches.config'
import { PartnerThemeProvider } from '@/src/common/contexts/PartnerThemeContext'
import { getWhiteLabelProgramConfigObjectFromProgram } from '@/src/modules/whiteLabelProgram/whiteLabelProgram.service'
import {
    TWhiteLabelProgramFrontEnd,
    WhiteLabelProgramSiteThemeConfig,
} from '@/src/modules/whiteLabelProgram/types'
import { useWhiteLabelTheme } from '@/src/common/hooks/useTheme'

const { publicRuntimeConfig } = getConfig()

config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

const apiKey = process.env.NEXT_PUBLIC_BUILDER_API_KEY
builder.init(apiKey ?? '')
Builder.isStatic = true
builder.allowCustomFonts = false

function isPath(router: Router, keywords: string[]): boolean {
    const path = router.asPath.split('/')[1]
    return Boolean(
        !router.isFallback && keywords.some((keyword) => path.includes(keyword))
    )
}

function isCorporatePath(router: Router): boolean {
    return isPath(router, ['corporate', 'lp'])
}

function isLPPath(router: Router): boolean {
    return isPath(router, ['lp'])
}

function isWhiteLabeledPage(router: Router): boolean {
    return router.pathname.startsWith('/_sites/')
}

export default function MyApp({ Component, pageProps, router }: AppProps) {
    const whiteLabelProgramConfig = pageProps?.whiteLabelProgram
        ? getWhiteLabelProgramConfigObjectFromProgram(
              pageProps?.whiteLabelProgram
          )
        : undefined
    const theme = useWhiteLabelTheme(router, pageProps) ?? defaultTheme

    let pageTitle = pageProps?.content?.data?.title?.includes('PerfectGift.com')
        ? pageProps?.content?.data?.title
        : pageProps?.content?.data?.title ?? ''
    pageTitle += pageProps?.whiteLabelProgram ? '' : ' | PerfectGift.com'

    const noIndexNoFollow =
        (pageProps?.content?.data?.meta?.noIndexNoFollow ?? false) ||
        pageProps?.whiteLabelProgram
    const pageDescription = pageProps?.content?.data?.description ?? ''
    const canonicalRef = pageProps?.content?.data?.canonicalRef
    const isCorporate = isCorporatePath(router)
    const isLP = isLPPath(router)

    const disableGoogleAnalytics = Boolean(pageProps?.disableGoogleAnalytics)
    const disableDataDome = Boolean(
        parseInt(String(process.env.NEXT_PUBLIC_DISABLE_DATA_DOME))
    )
    const showPrivateLabelPartnerLayout =
        pageProps?.showPrivateLabelPartnerLayout ?? false
    const hyrosEnabled = Boolean(
        parseInt(String(process.env.NEXT_PUBLIC_HYROS_ENABLED))
    )
    const [showAppLayout, setShowAppLayout] = useState(false)
    const [queryClient] = React.useState(() => new QueryClient())

    const [cookies, setCookie] = useCookies([
        UTM_SOURCE,
        UTM_MEDIUM,
        UTM_CAMPAIGN,
        UTM_ID,
        PARTNER_DATA_COOKIE,
        RISKIFIED_SESSION_ID,
    ])

    const [riskifiedSessionId, setRiskifiedSessionId] = useState(
        cookies[RISKIFIED_SESSION_ID] || uuidv4()
    )

    const LayoutComponent = showPrivateLabelPartnerLayout ? (
        <PrivateLabelPartnerLayout
            Component={Component}
            pageProps={pageProps}
        />
    ) : showAppLayout ? (
        <AppLayout Component={Component} pageProps={pageProps} />
    ) : (
        <GlobalLayout
            Component={Component}
            pageProps={pageProps}
            isCorporate={isCorporate}
            isLP={isLP}
        />
    )

    useEffect(() => {
        setShowAppLayout(isNativeApp)
    }, [isNativeApp])

    useEffect(() => {
        const isPageToNotInit: boolean = [
            '/',
            '/check-balance',
            '/activate',
        ].some((route) => route === router.asPath)

        if (!isPageToNotInit && publicRuntimeConfig.fullstoryOrgId) {
            try {
                // checks to see if there is an active FullStory session
                const sessionUrl = !!FullStory?.getCurrentSessionURL()
                console.log(
                    'FullStory has already been initialized',
                    sessionUrl
                )
            } catch {
                // if there isn't, init FullStory
                console.log('Initializing FullStory...')
                FullStory.init({
                    orgId: publicRuntimeConfig.fullstoryOrgId,
                    devMode: process.env.NODE_ENV !== 'production',
                })
            }
        }
    }, [router.asPath])

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            pageview(url)
            if (window.RISKX) {
                // Notify Riskified beacon about the page change
                window.RISKX.go(url)
            }
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        router.events.on('hashChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
            router.events.off('hashChangeComplete', handleRouteChange)
        }
    }, [router.events, cookies, setCookie])

    const urlPath = router.asPath.split('?')[0]

    useEffect(() => {
        const urlParams = new URLSearchParams(window?.location?.search)
        const utmSource = urlParams?.get(UTM_SOURCE)
        const utmMedium = urlParams?.get(UTM_MEDIUM)
        const utmCampaign = urlParams?.get(UTM_CAMPAIGN)
        const utmId = urlParams?.get(UTM_ID)
        // when ios launched we'll be setting an appType param in the launch url
        // that will tell it to hide the social login buttons
        const appType = urlParams?.get(APP_TYPE)
        const brickyardMsBuyer = urlParams?.get(BRICKYARD_MS_QUERY_PARAM)
        const utm = [
            { name: UTM_SOURCE, value: utmSource },
            { name: UTM_MEDIUM, value: utmMedium },
            {
                name: UTM_CAMPAIGN,
                value: utmCampaign,
            },
            { name: UTM_ID, value: utmId },
        ]

        utm.forEach((item) => {
            const { name, value } = item
            if (!!value && value !== get(cookies, name)) {
                setCookie(name, value)
            }
        })
        //if the url param appType exists then set it in the cookie
        //we'll use this later in the login form
        if (appType) {
            setCookie(APP_TYPE, appType)
        }
        if (brickyardMsBuyer) {
            setCookie(BRICKYARD_MS_BUYER, 1, {
                path: '/',
                domain: window.location.hostname,
            })
        }
    }, [cookies, setCookie, router.asPath])

    // handle displaying ADA chatbot on pages
    const path = router.asPath?.split('?')[0]
    const isCorporateOrLandingPage: boolean =
        isCorporate || path.includes('/lp')
    const adaDataHandle = isCorporateOrLandingPage
        ? 'perfectgift'
        : 'demo-perfectgift-gr'

    useEffect(() => {
        //TODO find a way to move the ada chat button in the apps so it doesn't cover bottom navigation
        const isValidPageForAda: boolean = !isNativeApp && !isExcludedPage(path)
        const adaScript = document.getElementById('__ada')
        const adaEntry = document.getElementById('ada-entry')

        // if invalid ada page and script exists, remove it
        if (!isValidPageForAda && !!adaScript) {
            document.body.removeChild(adaScript)

            // if ada entry is present, hide it
            if (adaEntry) {
                adaEntry.style.display = 'none'
            }

            return
        }

        // if valid page and no script, add it
        if (isValidPageForAda && !adaScript) {
            const script = document.createElement('script')
            script.src = 'https://static.ada.support/embed2.js'
            script.id = '__ada'
            script.async = true
            script.setAttribute('strategy', 'afterInteractive')
            script.setAttribute('data-handle', adaDataHandle)

            document.body.append(script)

            window.adaSettings = {
                handle: adaDataHandle,
                eventCallbacks: {
                    chat_lead: function () {
                        window.dataLayer = window.dataLayer || []
                        window.dataLayer.push({ event: 'chatLead' })
                    },
                },
            }

            // if ada entry is present, show it
            if (adaEntry) {
                adaEntry.style.display = 'block'
            }
        }

        // trigger or cancel ada campaigns
        handleAdaCampaign(path)
    }, [adaDataHandle, path])

    const onActive = (event: any) => {
        const newRiskifiedSid = uuidv4()
        // User came back from inactivity, create a new riskified session id
        setCookie(RISKIFIED_SESSION_ID, newRiskifiedSid)
        setRiskifiedSessionId(newRiskifiedSid)
        if (window.RISKX) {
            window.RISKX.setSid(newRiskifiedSid)
        }
    }

    useIdleTimer({
        onActive,
        timeout: 1000 * 60 * 30, // 30 minutes until considered inactive
        throttle: 500,
    })

    return (
        <PartnerThemeProvider
            theme={theme}
            whiteLabelProgram={
                pageProps?.whiteLabelProgram as TWhiteLabelProgramFrontEnd
            }
            whiteLabelProgramConfig={whiteLabelProgramConfig ?? undefined}
            isWhiteLabelPage={isWhiteLabeledPage(router)}
        >
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <link
                    key="canonical" // enables override on per-page basis
                    rel="canonical"
                    href={`https://www.perfectgift.com${
                        canonicalRef || urlPath
                    }`}
                />
                <link rel="manifest" href="/manifest.json" />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content="/images/perfect_gift_logo_og.png"
                />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="627" />
                {noIndexNoFollow && (
                    <meta name="robots" content="noindex,nofollow" />
                )}
            </Head>
            <React.Fragment>
                <Script src={'https://js.stripe.com/v3/'} async />
                {/*extole core script and createZone function*/}
                {/* {process.env.NODE_ENV !== 'development' && (
                        <>
                            <Script
                                type="text/javascript"
                                src="https://perfectgift.extole.io/core.js"
                                async
                            />
                            <Script
                                id={'extole-create-zone'}
                                type="text/javascript"
                                dangerouslySetInnerHTML={{
                                    __html: `
                        (function(c,e,k,l,a){c[e]=c[e]||{};for(c[e].q=c[e].q||[];a<l.length;)k(l[a++],c[e])})(window,"extole",function(c,e){e[c]=e[c]||function(){e.q.push([c,arguments])}},["createZone"],0);
                    `,
                                }}
                            />{' '}
                        </>
                    )} */}
                {!disableDataDome && (
                    <>
                        {/*datadome bot protection */}
                        <Script
                            strategy="afterInteractive"
                            id="load-datadome"
                        >{`
                                window.ddjskey = '${process.env.NEXT_PUBLIC_DATADOME_CLIENT_KEY}'
                                window.ddoptions = {
                                    endpoint: '${DATADOME_JS}',
                                    sessionByHeader: true,
                                    ajaxListenerPath: true
                                }
                            `}</Script>
                        <Script src={DATADOME_TAGS} strategy="lazyOnload" />
                    </>
                )}
                {!disableGoogleAnalytics && (
                    <>
                        {/* Global Site Tag (gtag.js) - Google Analytics */}
                        <Script
                            strategy="worker"
                            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
                        />
                        <Script
                            id="gtag-init"
                            strategy="afterInteractive"
                            dangerouslySetInnerHTML={{
                                __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${GA_TRACKING_ID}', {
                              page_path: window.location.pathname,
                            });
                          `,
                            }}
                        />
                        <Script
                            id="gtm-init"
                            strategy="afterInteractive"
                            dangerouslySetInnerHTML={{
                                __html: `
                        function font_faml_uyoqvna(w, d, s, l, i) {
                            w[l] = w[l] || [];
                            w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                            var f = d.getElementsByTagName(s)[0], j = d.createElement(s),
                            dl = l != 'dataLayer' ? '&l=' + l : '';
                            // j.async = true;
                            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                            f.parentNode.insertBefore(j, f);
                        }
                        font_faml_uyoqvna(window, document, 'script', 'dataLayer', "${process.env.NEXT_PUBLIC_GTM_ID}");
                `,
                            }}
                        />

                        <Script
                            id="riskified-beacon"
                            strategy="afterInteractive"
                            dangerouslySetInnerHTML={{
                                __html: `
                                    (function() {
                                        var riskifiedSessionIdVar = '${riskifiedSessionId}';
                                        var riskifiedCookieValue
                                        // Ensure we're running in the browser to get the cookie
                                        if (typeof window !== 'undefined') {
                                            // Read all cookies
                                            const allCookies = document.cookie;
    
                                            // Optionally read a specific cookie
                                            riskifiedCookieValue = allCookies
                                            .split('; ')
                                            .find(row => row.startsWith('riskifiedSessionId='))
                                            ?.split('=')[1];
    
                                            // If 'riskifiedSessionId' is not set, set the cookie with the provided session ID
                                            if (!riskifiedCookieValue) {
                                                document.cookie = 'riskifiedSessionId=' + riskifiedSessionIdVar + '; path=/';
                                                riskifiedCookieValue = riskifiedSessionIdVar;
                                            }
                                        }
    
                                        function riskifiedBeaconLoad() {
                                            var store_domain = 'perfectgift.com';
                                            var session_id = riskifiedCookieValue ? riskifiedCookieValue : riskifiedSessionIdVar;
                                            var url = ('https:' == document.location.protocol ? 'https://' : 'http://')
                                            + "beacon.riskified.com?shop=" + store_domain + "&sid=" + session_id;
                                            var s = document.createElement('script');
                                            s.type = 'text/javascript';
                                            s.async = true;
                                            s.src = url;
                                            var x = document.getElementsByTagName('script')[0];
                                            x.parentNode.insertBefore(s, x);
                                        }
                                        if (window.attachEvent)
                                            window.attachEvent('onload', riskifiedBeaconLoad)
                                        else
                                            window.addEventListener('load', riskifiedBeaconLoad, false);
                                        })();
                                    `,
                            }}
                        />

                        {hyrosEnabled ? (
                            <>
                                {/* Hyros attribution - only run in production */}
                                <Script
                                    id="hyros-script"
                                    strategy="lazyOnload"
                                    dangerouslySetInnerHTML={{
                                        __html: `
                                            (function() {
                                            var head = document.head;
                                            var script = document.createElement('script');
                                            script.type = 'text/javascript';
                                            script.src = "https://t.perfectgift.com/v1/lst/universal-script?ph=77d1be17333dc7b3c18cb8e26a591078523285c0b300283380d0b51bd3af8c8e&tag=!clicked&ref_url=" + encodeURI(document.URL);
                                            head.appendChild(script);
                                            })();
                                        `,
                                    }}
                                />
                            </>
                        ) : null}
                    </>
                )}
            </React.Fragment>
            <AuthProvider>
                <IsNativeProvider>
                    <KeyboardProvider>
                        <CookiesProvider>
                            <QueryClientProvider client={queryClient}>
                                {LayoutComponent}
                                <ReactQueryDevtools initialIsOpen={false} />
                                <SpeedInsights sampleRate={0.8} />
                            </QueryClientProvider>
                        </CookiesProvider>
                    </KeyboardProvider>
                </IsNativeProvider>
            </AuthProvider>
        </PartnerThemeProvider>
    )
}
