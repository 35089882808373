import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { theme as defaultTheme } from '@/src/stitches.config'
import {
    WhiteLabelProgramConfig,
    TWhiteLabelProgramFrontEnd,
} from '@/src/modules/whiteLabelProgram/types'

type PartnerThemeContextType = {
    theme: typeof defaultTheme // Themes should be extensions of defaulTheme, so we can safely cast it to typeof defaultTheme
    whiteLabelProgramConfig?: WhiteLabelProgramConfig
    isWhiteLabelPage?: boolean
    whiteLabelProgram?: TWhiteLabelProgramFrontEnd
}

const PartnerThemeContext = createContext<PartnerThemeContextType | undefined>(
    undefined
)

type PartnerThemeProviderProps = {
    children: ReactNode
    theme: typeof defaultTheme // Themes should be extensions of defaulTheme, so we can safely cast it to typeof defaultTheme
    whiteLabelProgramConfig?: WhiteLabelProgramConfig
    isWhiteLabelPage?: boolean
    whiteLabelProgram?: TWhiteLabelProgramFrontEnd
}

export const PartnerThemeProvider = ({
    children,
    theme,
    whiteLabelProgramConfig,
    isWhiteLabelPage,
    whiteLabelProgram,
}: PartnerThemeProviderProps) => {
    const contextValue = useMemo(
        () => ({
            theme,
            whiteLabelProgram,
            whiteLabelProgramConfig,
            isWhiteLabelPage,
        }),
        [theme, whiteLabelProgram, whiteLabelProgramConfig, isWhiteLabelPage]
    )

    return (
        <PartnerThemeContext.Provider value={contextValue}>
            <div className={theme?.className}>{children}</div>
        </PartnerThemeContext.Provider>
    )
}

export const usePartnerTheme = () => {
    const context = useContext(PartnerThemeContext)

    // Return default PGC theme with default values for other properties
    if (!context) {
        return {
            theme: defaultTheme as typeof defaultTheme,
            whiteLabelProgramConfig: {} as WhiteLabelProgramConfig,
            isWhiteLabelPage: false,
            whiteLabelProgram: {} as TWhiteLabelProgramFrontEnd,
        }
    }
    return context
}
