import prisma from '../../lib/prisma'

export const findByUuid = async (uuid: string) => {
    return prisma.whiteLabelProgram.findUniqueOrThrow({
        where: {
            uuid: uuid,
        },
    })
}

export const findById = async (id: number) => {
    return prisma.whiteLabelProgram.findUniqueOrThrow({
        where: {
            id: id,
        },
    })
}

export const findByName = async (name: string) => {
    return prisma.whiteLabelProgram.findFirst({
        where: {
            name: name,
        },
    })
}

export const findBySubdomain = async (subdomain: string) => {
    console.info('whiteLabelRepository::findBySubdomain', subdomain)
    return prisma.whiteLabelProgram.findFirst({
        where: {
            subdomain: subdomain,
        },
    })
}

export const findProgramConfigBySubdomain = async (subdomain: string) => {
    const whiteLabelProgram = await findBySubdomain(subdomain)
    console.info(
        'whiteLabelRepository::findProgramConfigByName',
        whiteLabelProgram
    )
    let programConfig
    if (whiteLabelProgram?.program_config) {
        if (typeof whiteLabelProgram.program_config === 'string') {
            try {
                programConfig = JSON.parse(
                    whiteLabelProgram.program_config as string
                )
            } catch (error) {
                console.error('Error parsing program_config:', error)
            }
        } else {
            programConfig = whiteLabelProgram.program_config
        }
    }
    return programConfig
}

export const findProgramEmailConfigByIdAndEmailTemplateSlug = async (
    whiteLabelProgramId: number,
    emailTemplateSlug: string
) => {
    return prisma.whiteLabelProgramEmailConfig.findFirst({
        where: {
            white_label_program_id: whiteLabelProgramId,
            email_template_slug: emailTemplateSlug,
        },
    })
}
