import {
    findProgramEmailConfigByIdAndEmailTemplateSlug,
    findBySubdomain,
} from '../../../prisma/repository/whiteLabelRepository'
import type {
    TWhiteLabelProgramFrontEnd,
    WhiteLabelProgramConfig,
} from './types'
import { WhiteLabelProgram } from '@prisma/client'

export const getWhiteLabelProgramEmailConfig = async (
    whiteLabelProgramId: number,
    emailTemplate: string
) => {
    try {
        return await findProgramEmailConfigByIdAndEmailTemplateSlug(
            whiteLabelProgramId,
            emailTemplate
        )
    } catch (error: any) {
        console.error(`getWhiteLabelProgramEmailConfig:error-fetching`, {
            error: error?.message,
            whiteLabelProgramId,
            emailTemplate,
        })
        return null
    }
}

export const getWhiteLabelProgramConfigBySubdomain = async (
    whiteLabelProgramSlug: string
): Promise<WhiteLabelProgramConfig | null> => {
    try {
        console.info('service::getWhiteLabelProgramBySlug - begin method', {
            whiteLabelProgramSlug: whiteLabelProgramSlug,
        })

        const whiteLabelProgram = await findBySubdomain(whiteLabelProgramSlug)

        let whiteLabelProgramConfig = null
        if (whiteLabelProgram?.program_config) {
            if (typeof whiteLabelProgram.program_config === 'string') {
                try {
                    whiteLabelProgramConfig = JSON.parse(
                        whiteLabelProgram.program_config as string
                    ) as WhiteLabelProgramConfig
                } catch (error) {
                    console.error(
                        'service::getWhiteLabelProgramConfigBySubdomain - Error parsing program_config:',
                        error
                    )
                }
            } else {
                whiteLabelProgramConfig =
                    whiteLabelProgram.program_config as WhiteLabelProgramConfig
            }
        }

        if (whiteLabelProgramConfig && whiteLabelProgram?.id) {
            whiteLabelProgramConfig.id = whiteLabelProgram.id
        }

        return whiteLabelProgramConfig
    } catch (error) {
        console.error(
            `service::getWhiteLabelProgramConfigBySubdomain - Error fetching config for white label program ID: ${whiteLabelProgramSlug}`,
            error
        )
        return null
    }
}

export const getWhiteLabelProgramConfigObjectFromProgram = (
    whiteLabelProgram: WhiteLabelProgram | TWhiteLabelProgramFrontEnd
): WhiteLabelProgramConfig | null => {
    let whiteLabelProgramConfig = null
    try {
        if (typeof whiteLabelProgram.program_config === 'string') {
            try {
                whiteLabelProgramConfig = JSON.parse(
                    whiteLabelProgram.program_config as string
                ) as WhiteLabelProgramConfig
            } catch (error) {
                console.error(
                    'service::getWhiteLabelProgramConfigObjectFromProgram - Error parsing program_config:',
                    error
                )
            }
        } else {
            whiteLabelProgramConfig =
                whiteLabelProgram.program_config as WhiteLabelProgramConfig
        }
        return whiteLabelProgramConfig
    } catch (error) {
        console.error(
            `service::getWhiteLabelProgramConfigObjectFromProgram - Error fetching config for white label program ID: ${whiteLabelProgram?.name}`,
            error
        )
        return null
    }
}
