import { registerPlugin } from '@capacitor/core'
import { safeCapacitorCall } from '@/src/common/utilities/capacitorUtils'

interface NavigationModePlugin {
    isButtonNavigationEnabled(): Promise<{ isButtonEnabled: boolean }>
}

const NavigationMode = registerPlugin<NavigationModePlugin>('NavigationMode')

export async function checkButtonNavigation(): Promise<boolean> {
    const result = await safeCapacitorCall(() =>
        NavigationMode.isButtonNavigationEnabled()
    )

    return result?.isButtonEnabled ?? false
}
