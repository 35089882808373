export enum CobrandSteps {
    AmountAndDesign = 'amount-design',
    QuantityAndRecipientsCustomized = 'quantity-recipients',
    QuantityAndRecipientsBox = 'quantity-recipients-box',
    PackagingSelect = 'packaging',
    CarrierCustomization = 'carrier-customization',
    GreetingCardDesign = 'greeting-card-design',
    GreetingCardCustomization = 'greeting-card-customization',
}

export enum CobrandLimits {
    QuantityMin = 1,
    QuantityMinBox = 5,
    QuantityMax = 50,
    AmountMin = 10,
    AmountMax = 500,
    Expiration = '12 months',
    RequiresApplication = 'Yes',
}

export const CORPORATE_URL = '/corporate/get-started'
export const CORPORATE_CTA_TEXT =
    'Buying more than 50 cards or over $10,000 in load value? Talk to an Account Executive in minutes!'

export const ICON_PLUS = 'plus'
export const ICON_MINUS = 'minus'

export const DEFAULT_DENOM_COBRAND = '100'
export const DEFAULT_DENOM_VIRTUAL = '100'
export const DEFAULT_DENOM_BRANDED = '100'
export const DEFAULT_DENOM_PG_PLUS = '100'
export const DEFAULT_DENOM_PHYSICAL = '50'

export enum LoadingTypes {
    Checkout = 'checkout',
    Continue = 'continue',
}

export enum RecipientTypes {
    Unified = 'unified',
    Plastic = 'plastic',
}

export enum PlasticTypes {
    Consumer = 'consumer',
    SMB = 'smb',
}

// Cloudinary Values
export const CLOUDINARY_COBRAND_PRESET = 'pgsite_custom_design_uploads'
export const CLOUDINARY_COBRAND_VISA_OVERLAY_WITH_YOUR_LOGO_HERE =
    'https://res.cloudinary.com/gift-card-granny/image/upload/v1729716180/GCG/card_overlays/Visa_Reward_YLH_lines3-4_v4ac3w.png'
export const CLOUDINARY_COBRAND_VISA_OVERLAY_WITHOUT_NAME_URL =
    'https://res.cloudinary.com/gift-card-granny/image/upload/v1729778282/GCG/card_overlays/Visa_Reward_Number_Lines3-4_vr6mfg.png'
export const CLOUDINARY_COBRAND_VISA_OVERLAY_NO_NUMBERS_OR_LOGO_URL =
    'https://res.cloudinary.com/gift-card-granny/image/upload/v1729716411/GCG/card_overlays/Visa_Reward_blank_dsy1fg.png'

/*
    Card Builder Values
    - card is 3.375in x 2.125in
    - c80 card image dimensions 2.13" x 3.38"
    - 324 pixels x 204 pixels
    - width for 300dpi calc = .9 * 3.375 = 3.0375
    - height = .4 * 2.125 = .85
    - = 911 x 255 pix <-- magic numbers to determine how large the logo needs to be
    a full art card image needs to be at least = 1013 x 638 pix
*/
export const DEFAULT_DESCRIPTOR_COLOR = 'white'
export const DEFAULT_COBRAND_DESCRIPTOR_TEXT = 'REWARD CARD'
export const CARD_PROPORTION = 1.5625 // Proportion of width to height.
export const CARD_SCALE = 2
export const CARD_WIDTH = 325
export const CARD_HEIGHT = CARD_WIDTH / CARD_PROPORTION

export const CARD_WIDTH_DEFAULT = CARD_WIDTH * CARD_SCALE
export const CARD_HEIGHT_DEFAULT = CARD_HEIGHT * CARD_SCALE

export const PRINT_QUALITY_WIDTH = 2100 // px
export const PRINT_QUALITY_HEIGHT = PRINT_QUALITY_WIDTH / CARD_PROPORTION
const PRINT_QUALITY_GUTTER_X = 38 // px
const PRINT_QUALITY_GUTTER_Y = 35 // px
export const PRINT_QUALITY_WIDTH_SCALE =
    PRINT_QUALITY_WIDTH / CARD_WIDTH_DEFAULT
export const PRINT_QUALITY_HEIGHT_SCALE =
    PRINT_QUALITY_HEIGHT / CARD_HEIGHT_DEFAULT

export const LOGO_WIDTH_DEFAULT = CARD_WIDTH * 0.9 * CARD_SCALE
export const LOGO_HEIGHT_DEFAULT = CARD_HEIGHT * 0.1 * CARD_SCALE
export const DEFAULT_LOAD_VALUE = 50

export const BASE_PAD_PERCENT_FROM_EDGE = 0.05
export const PAD_PERCENT_FROM_EDGE_X =
    BASE_PAD_PERCENT_FROM_EDGE + PRINT_QUALITY_GUTTER_X / PRINT_QUALITY_WIDTH
export const PAD_PERCENT_FROM_EDGE_Y =
    BASE_PAD_PERCENT_FROM_EDGE + PRINT_QUALITY_GUTTER_Y / PRINT_QUALITY_HEIGHT

export const SMB_ERROR_AGREE_RIGHTS =
    'Please acknowledge you have rights to use all logos, any images, and/or text'

export type TCobrandDesign = {
    cobrandId: string
    name: string
    backgroundImage: string
    logoImage: string
    logoZoom: number
    maxLogoZoom: number
    logoPlacementError: string
    initialLogoPlaced: boolean
    logoX: number
    logoY: number
    previewBlobUrl: string
    rawBlobUrl: string
    isCobrand: boolean
    // not currently using for anything
    rawBackgroundToCrop: string
    audience: string
    cardNetwork: 'visa'
    //
}
