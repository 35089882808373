import {
    DEFAULT_BUY_PAGE,
    PARTNER_BRAND_PAGE,
    PARTNER_PATH,
    WHITELABEL_PURCHASE_PAGE,
} from '@/src/modules/pg-plus/constants'
import { TPartnerPayload } from '@/src/modules/partners/types'
import { TWhiteLabelProgramFrontEnd } from '../../whiteLabelProgram/types'

function determineBuyPage(
    defaultPath: string,
    partnerData?: TPartnerPayload,
    whiteLabelProgram?: TWhiteLabelProgramFrontEnd
): string {
    if (!partnerData && !whiteLabelProgram) {
        return defaultPath ?? DEFAULT_BUY_PAGE
    }

    if (whiteLabelProgram) {
        return WHITELABEL_PURCHASE_PAGE
    }

    if (partnerData?.store_page_brand_slug) {
        return `${PARTNER_PATH}/${partnerData.store_page_brand_slug}`
    }

    return PARTNER_BRAND_PAGE
}

export default determineBuyPage
