import { registerPlugin } from '@capacitor/core'

export type DisplayInsetsPlugin = {
    getDisplayInsets(): Promise<{
        hasCutout: boolean
        safeInsetTop: number
        safeInsetBottom: number
        safeInsetLeft: number
        safeInsetRight: number
    }>
}

const DisplayInsets = registerPlugin<DisplayInsetsPlugin>('DisplayInsets')

export async function getDisplayInsets(): Promise<{
    hasCutout: boolean
    safeInsetTop: number
    safeInsetBottom: number
    safeInsetLeft: number
    safeInsetRight: number
}> {
    if (DisplayInsets) {
        return DisplayInsets.getDisplayInsets()
    } else {
        return {
            hasCutout: true,
            safeInsetTop: 0,
            safeInsetBottom: 0,
            safeInsetLeft: 0,
            safeInsetRight: 0,
        }
    }
}

export { DisplayInsets }
