import {
    DisplayWhenTypes,
    IMobileNavBaseItem,
} from '@/src/common/components/elements/Layout/Navigation/types'
import ContactUsText from '@/src/common/components/elements/Text/ContactUsText'
import {
    PARTNER_BRAND_PAGE,
    PARTNER_LOGIN_PAGE,
    PARTNER_ORDER_STATUS_PAGE,
} from '@/src/modules/pg-plus/constants'
import { signOut } from '@/src/modules/login/FirebaseAuth'

export function createPartnerMobileNavMenuData(): IMobileNavBaseItem[] {
    return [
        {
            label: 'Brands',
            url: PARTNER_BRAND_PAGE,
            testId: 'mobile-brands',
        },
        {
            label: '24/7 Help',
            testId: 'mobile-help-section',
            subSection: [
                {
                    label: '',
                    testId: 'mobile-help-inner',
                    children: [
                        {
                            label: 'Visit our help center',
                            gradientText: 'help center',
                            url: 'https://perfectgiftsupport.zendesk.com/hc/en-us',
                            testId: 'mobile-help-center',
                            icon: 'fa-square-question',
                        },
                        {
                            label: 'Track your order',
                            gradientText: 'Track',
                            url: PARTNER_ORDER_STATUS_PAGE,
                            testId: 'mobile-track',
                            icon: 'fa-truck-fast',
                        },
                    ],
                    formattedContent: ContactUsText(),
                },
            ],
        },
        {
            label: 'Log In',
            displayWhen: DisplayWhenTypes.LOGGED_OUT,
            testId: 'mobile-login-section',
            subSection: [
                {
                    label: 'For individuals',
                    testId: 'mobile-individuals',
                    children: [
                        {
                            label: 'Log in to PerfectGift',
                            url: PARTNER_LOGIN_PAGE,
                            testId: 'mobile-login-consumer',
                            icon: 'fa-gift',
                        },
                    ],
                },
            ],
        },
        {
            label: 'My Account',
            displayWhen: DisplayWhenTypes.LOGGED_IN,
            testId: 'mobile-login-section',
            subSection: [
                {
                    label: 'For individuals',
                    testId: 'mobile-individuals',
                    children: [
                        {
                            label: 'Sign Out',
                            clickAction: () => signOut(),
                            testId: 'mobile-login-consumer',
                            icon: 'fa-right-to-bracket',
                        },
                    ],
                },
            ],
        },
    ]
}
