import { useState } from 'react'
import { styled } from '@/src/stitches.config'
import Image from 'next/image'
import ClipLoader from 'react-spinners/ClipLoader'

const Container = styled('div', {
    background: 'white',
    maxWidth: '475px',
    width: '100%',
    boxShadow: 'grey 0px 1px 11px -5px',
    position: 'relative',
    '.bold': {
        fontWeight: 'bold',
    },
})

const SupportDetails = styled('div', {
    width: '30%',
    position: 'absolute',
    top: '4%',
    left: '9%',
    zIndex: 1,
    fontSize: 10,
    '& p': {
        m: 0,
    },
    '@media (max-width: 399px)': {
        fontSize: 8,
    },
})

const CustomDetails = styled('div', {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    zIndex: 1,
    top: '14%',
    left: '9%',
    fontSize: 11,
    '& p': {
        m: '0 0 4px',
    },
    '.padding-left': {
        pl: '5%',
    },
    '@media (max-width: 399px)': {
        fontSize: 9,
        '& p': {
            m: '0 0 1px',
        },
    },
    '@media (min-width: 600px)': {
        fontSize: 12,
    },
})

const HalfContent = styled('div', {
    width: '45%',
    overflowWrap: 'break-word',
    pr: '4%',
    '& p': {
        hyphens: 'auto',
    },
})

const ImageContainer = styled('div', {
    position: 'relative',
    display: 'flex',
    '& img': {
        width: '100%',
        height: 'auto',
    },
    '.card-image': {
        position: 'absolute',
        width: '36%',
        top: '40%',
        left: '7%',
        background: 'white',
        br: 7,
        display: 'flex',
        '& img': {
            br: 7,
        },
    },
})
const FakeExpiration = styled('p', {
    position: 'absolute',
    color: '$light',
    textShadow: '0px 0px 2px #000',
    top: '50.8%',
    left: '25%',
    fontSize: '4px',
    '@sm': {
        fontSize: '5px',
    },
})

const ImgProgress = styled(ClipLoader, {
    position: 'absolute',
    top: '43%',
    left: '44%',
    zIndex: 4,
})

interface ICarrierProps {
    cloudinary: string
    denomination: string
    recipientName: string
    recipientMessage: string
    senderName: string
}

const Carrier = ({
    cloudinary,
    denomination,
    recipientMessage,
    recipientName,
    senderName,
}: ICarrierProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const currentDate = new Date().getFullYear()
    const futureDate = new Date(currentDate + 7, 7, 18).toLocaleDateString(
        'en',
        {
            month: '2-digit',
            year: '2-digit',
        }
    )
    return (
        <Container className={'carrier'}>
            {isLoading && (
                <ImgProgress
                    id={'loader'}
                    color={'gray'}
                    loading={isLoading}
                    size={40}
                />
            )}
            <SupportDetails className={'support-details'}>
                <p>Customer Service</p>
                <p>495 Mansfield Avenue</p>
                <p>Pittsburgh, PA 15205</p>
            </SupportDetails>
            <CustomDetails className={'custom-details'}>
                <HalfContent className={'padding-left'}>
                    <p>Recipient Name</p>
                    <p>Address 1</p>
                    <p>City, State Zip Code</p>
                    <p>Order Number</p>
                </HalfContent>
                <HalfContent>
                    <p>
                        <span className={'bold'}>To: </span>
                        {recipientName}
                    </p>
                    <p>
                        <span className={'bold'}>From: </span>
                        {senderName}
                    </p>
                    <p>
                        <span className={'bold'}>Amount: </span>${denomination}
                    </p>
                    <p>
                        <span className={'bold'}>Message: </span>
                        {recipientMessage}
                    </p>
                </HalfContent>
            </CustomDetails>
            <ImageContainer>
                <Image
                    src={
                        'https://res.cloudinary.com/gift-card-granny/image/upload/f_auto,w_800/v1668439234/PGSite/carriers/PG_openloop_gift_carrier-front_f4nime.png'
                    }
                    height={800}
                    width={618}
                    alt={'PerfectGift.com Carrier Image'}
                    onLoad={() => setIsLoading(false)}
                />
                <Image
                    src={cloudinary}
                    height={280}
                    width={400}
                    alt={'Gift Image'}
                    className={'card-image'}
                    priority={true}
                />
                <FakeExpiration className={'gift-expiration'}>
                    {futureDate}
                </FakeExpiration>
            </ImageContainer>
        </Container>
    )
}

export default Carrier
