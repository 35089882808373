export const hideBackArrowPaths = [
    '/',
    '/visa-gift-cards',
    '/native-app/app-cart',
    '/login',
    '/native-app/more',
    '/account',
    '/perfectgift-plus',
    '/brands',
    '/custom-greeting-cards',
    '/native-app/app-homepage',
]
