import { useEffect, useState, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { styled } from '@stitches/react'
import { App } from '@capacitor/app'
import useAppPaddingTop from '@/src/common/hooks/useGetAppPaddingTop'

const HeaderContainer = styled('div', {
    backgroundColor: '#A800FF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 20px',
    color: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    WebkitOverflowScrolling: 'touch',
    zIndex: 1000,
})

const TopRow = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '14px',
})

const IconContainer = styled('div', {
    cursor: 'pointer',
})

const Title = styled('h1', {
    flex: 1,
    textAlign: 'center',
    margin: 0,
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white',
})

const BasicAppHeader = ({
    onBackClick,
    showBackArrow,
    extraHeight = 0,
    children,
    title = 'PerfectGift.com',
}: {
    onBackClick: () => void
    showBackArrow: boolean
    extraHeight?: number
    children?: ReactNode
    title?: string
}) => {
    const [height, setHeight] = useState('60px')
    const [paddingTop, setPaddingTop] = useState('40px')
    const appPaddingTop = useAppPaddingTop()

    useEffect(() => {
        const additionalHeightValue = parseInt(appPaddingTop, 10)
        setHeight(`${60 + additionalHeightValue + (extraHeight ?? 0)}px`)
        setPaddingTop(appPaddingTop)
    }, [appPaddingTop, extraHeight])

    useEffect(() => {
        const handleBackButton = () => {
            if (showBackArrow) {
                onBackClick()
            }
        }

        App.addListener('backButton', handleBackButton)

        return () => {
            App.removeAllListeners()
        }
    }, [showBackArrow, onBackClick])

    return (
        <HeaderContainer id="app-header-id" style={{ height, paddingTop }}>
            <TopRow>
                {showBackArrow && (
                    <IconContainer onClick={onBackClick}>
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            style={{ fontSize: '24px', color: 'white' }}
                        />
                    </IconContainer>
                )}
                <Title>{title}</Title>
            </TopRow>
            {children}
        </HeaderContainer>
    )
}

export default BasicAppHeader
