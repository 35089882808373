export const COBRAND_PENDING = 'PENDING'
export const COBRAND_REVIEW = 'REVIEW'
export const COBRAND_APPROVED = 'APPROVED'
export const COBRAND_REJECTED = 'REJECTED'

export const DL_LARGE_ORDER_WARNING = 'largeOrderWarning'
export const DL_OVER_WEEKLY_SMB_LIMIT_ERROR = 'overWeeklySMBLimitError'

export const DL_USER_TYPE_SMB = 'smb'
export const DL_USER_TYPE_CONSUMER = 'consumer'
