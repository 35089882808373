import { useEffect, useState } from 'react'

const useStatusBarAdjustment = () => {
    const [isLandscape, setIsLandscape] = useState(false)

    useEffect(() => {
        const handleOrientationChange = () => {
            setIsLandscape(window.innerWidth > window.innerHeight)
        }

        window.addEventListener('resize', handleOrientationChange)
        handleOrientationChange()

        return () => {
            window.removeEventListener('resize', handleOrientationChange)
        }
    }, [])

    return { isLandscape }
}

export default useStatusBarAdjustment