import determineFAIcon from '@/src/common/components/elements/Layout/Navigation/determineFAIcon'
import { styled } from '@/src/stitches.config'
import {
    gradientWordsStyles,
    replaceGradientWords,
} from '@/src/common/utilities/replaceGradientWords'
import Image from 'next/legacy/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ImageLink = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    py: '8px',
    pl: '$2',
    br: '$3',
    '.gradient-words': gradientWordsStyles.normal,
    '&:hover': {
        bc: '$lightest',
        '.gradient-words': gradientWordsStyles.hover,
    },
})

const ImageLinkImageWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
})

const ImageLinkImage = styled(Image, {
    objectFit: 'contain',
    borderRadius: '3px',
})

const IconLinkWrapper = styled('div', {
    width: '3em',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
        color: '$secondary',
        display: 'inline-block',
    },
})

const IconLink = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    height: '4em',
    br: '$3',
    '.gradient-words': gradientWordsStyles.normal,
    '&:hover': {
        bc: '$lightest',
        '.gradient-words': gradientWordsStyles.hover,
    },
})

const Icon = styled(FontAwesomeIcon, {
    '[data-state=open] &': {
        color: '$secondary',
        flexGrow: 1,
    },
    '@media (prefers-reduced-motion: no-preference)': {
        transition: 'transform 250ms ease',
    },
})

const NavLinkWrapperOuter = styled('div', {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    opacity: 1,
    color: '$darkest',
})
const NavLinkWrapperInner = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    pl: 10,
    fontWeight: 700,
    opacity: 1,
    color: '$darkest',
})

const NavLink = styled('div', {
    fontSize: '1em',
    fontWeight: 700,
    opacity: 1,
    color: '$darkest',
    cursor: 'pointer',
    pr: 10,
    '&.view-all-link': {
        py: '25px',
        color: '$secondary',
        '&:hover': {
            color: '$secondaryDark',
        },
    },
    '&.simple-link': {
        py: '5px',
        width: '100%',
        '&:hover': {
            color: '$secondaryDark',
        },
    },
    '&.label-suffix': {
        width: '300px',
    },
    '&:hover': {
        bc: 'transparent',
    },
})

const SubLabel = styled('div', {
    fontSize: '0.83em',
    fontWeight: 300,
    color: '$dark',
    mt: '5px',
})

const LabelSuffixNavLinkWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
    marginBottom: '0.5rem',
})

const LabelSuffix = styled(SubLabel, {
    whiteSpace: 'nowrap',
    mt: 0,
    fontSize: '0.875rem',
})

interface INavLinkWithGradientText {
    linkText: string
    gradientWords: string | undefined
}

const NavLinkWithGradientText = ({
    linkText,
    gradientWords,
}: INavLinkWithGradientText) => {
    if (!gradientWords || linkText.indexOf(gradientWords) < 0) {
        return <NavLink>{linkText}</NavLink>
    }
    return <NavLink>{replaceGradientWords(linkText, gradientWords)}</NavLink>
}

type TLinkProps = {
    label: string
    image?: string
    icon_class?: string
    gradient_words?: string
    sub_label?: string
    label_suffix?: string
    openChatBox?: boolean
    is_view_all?: boolean
}

function determineNavLinkContent({
    image,
    icon_class,
    label,
    gradient_words,
    sub_label,
    label_suffix,
    openChatBox,
    is_view_all,
}: TLinkProps) {
    if (image) {
        return (
            <ImageLink role={'button'}>
                <ImageLinkImageWrapper>
                    <ImageLinkImage
                        src={image}
                        alt={label}
                        width={100}
                        height={63}
                        data-testid={'image_link_image'}
                    />
                </ImageLinkImageWrapper>
                <NavLinkWrapperOuter>
                    <NavLinkWrapperInner>
                        {gradient_words ? (
                            <NavLinkWithGradientText
                                linkText={label}
                                gradientWords={gradient_words}
                            />
                        ) : (
                            <NavLink>{label}</NavLink>
                        )}
                        <SubLabel>{sub_label}</SubLabel>
                    </NavLinkWrapperInner>
                </NavLinkWrapperOuter>
            </ImageLink>
        )
    }

    if (icon_class) {
        const iconClass = determineFAIcon(icon_class)
        return (
            <IconLink
                role={'button'}
                onClick={(e) => {
                    if (openChatBox) {
                        e.preventDefault()
                        try {
                            window?.adaEmbed?.toggle()
                        } catch {
                            console.log('Unable to open ADA chat window')
                        }
                    }
                }}
            >
                <IconLinkWrapper>
                    {iconClass && (
                        <Icon
                            size={'2x'}
                            icon={iconClass}
                            data-testid={'icon_link_icon'}
                        />
                    )}
                </IconLinkWrapper>
                <NavLinkWrapperOuter>
                    <NavLinkWrapperInner>
                        {gradient_words ? (
                            <NavLinkWithGradientText
                                linkText={label}
                                gradientWords={gradient_words}
                            />
                        ) : (
                            <NavLink>{label}</NavLink>
                        )}
                        <SubLabel>{sub_label}</SubLabel>
                    </NavLinkWrapperInner>
                </NavLinkWrapperOuter>
            </IconLink>
        )
    }

    return (
        <NavLinkWrapperOuter>
            <NavLinkWrapperInner>
                {label_suffix ? (
                    <LabelSuffixNavLinkWrapper>
                        <NavLink
                            className={`label-suffix ${
                                is_view_all ? 'view-all-link' : 'simple-link'
                            }`}
                            data-testid={'nav-link'}
                            role={'button'}
                        >
                            {label}
                        </NavLink>
                        <LabelSuffix>{label_suffix}</LabelSuffix>
                    </LabelSuffixNavLinkWrapper>
                ) : (
                    <>
                        <NavLink
                            className={
                                is_view_all ? 'view-all-link' : 'simple-link'
                            }
                            data-testid={'nav-link'}
                            role={'button'}
                        >
                            {label}
                        </NavLink>
                        <SubLabel>{sub_label}</SubLabel>
                    </>
                )}
            </NavLinkWrapperInner>
        </NavLinkWrapperOuter>
    )
}

export default determineNavLinkContent
