export enum TkYCStatus {
    APPROVED = 'approved',
    REJECTED = 'rejected',
    PENDING = 'pending',
    NOT_SUBMITTED = 'not-submitted',
}

export enum TOrderLimitsCodes {
    // codes for has kyc application not rejected - limit 10,000
    KYC_OVER_WEEK_LIMIT = 'kyc-over-week-limit', // canot purchase more
    ORDER_BREAKS_KYC_WEEK_LIMIT = 'order-breaks-kyc-week-limit', // must remove items

    // codes for orders by buyer without kyc application or rejected kyc application - limit 2000
    OVER_WEEK_LIMIT = 'over-week-limit', // will need to submit kyc application to purchase more
    ORDER_BREAKS_WEEK_LIMIT = 'order-breaks-week-limit', // remove items OR submit kyc application

    // requires that a user submit a kyc application or remove smb items from cart
    SMB = 'smb-card-in-cart',
}

export enum TOrderLimitsCodeType {
    CARD_COUNT = 'card-count',
    ORDER_COUNT = 'order-count',
    PURCHASE_AMOUNT = 'purchase-amount',
    SMB = 'smb',
}

export type PurchaseLimitsReturnItem = {
    code: string
    type: string
    overAmount?: undefined | number
}

export type PurchaseLimitsReturn = {
    error?: {
        error_message: string
        code: number
    }
    limits_result?: PurchaseLimitsReturnItem[] | null | undefined
}

export type TOrderLimitDetails = {
    requiresKyc: boolean
    overage: {
        amount: number
        type: TOrderLimitsCodeType | undefined
        code: TOrderLimitsCodes | undefined
    }
    KYCIsOverWeeklyLimit: boolean
    consumerOrderIsOverWeeklyLimit: boolean
}
