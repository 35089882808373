import MobileNavDrawer from '@/src/common/components/elements/Layout/Navigation/Mobile/MobileNavDrawer'
import LogoLink from '@/src/common/components/elements/Layout/LogoLink'
import {
    HeaderTopContentLeft,
    HideOnMobileWrapper,
} from '@/src/common/components/elements/Navigation/header/StyledComponents'
import { TPartnerPayload } from '@/src/modules/partners/types'
import { TWhiteLabelProgramFrontEnd } from '@/src/modules/whiteLabelProgram/types'
import { DynamicHeaderSearchBar } from '@/src/modules/algolia/header-search/DynamicHeaderSearchBar'

type TLogoNavProps = {
    isCorporate: boolean
    partnerData?: TPartnerPayload
    showMobileNav?: boolean
    imageMinWidth?: string | number
    whiteLabelProgram?: TWhiteLabelProgramFrontEnd
    showSearchBar?: boolean
}

const HeaderLogoAndMobileNav = ({
    partnerData,
    isCorporate,
    showMobileNav,
    whiteLabelProgram,
    showSearchBar = true,
}: TLogoNavProps) => {
    return (
        <HeaderTopContentLeft>
            {showMobileNav && (
                <MobileNavDrawer
                    partner={partnerData?.partnerName ?? ''}
                    isCorporate={isCorporate}
                    whiteLabelProgram={whiteLabelProgram}
                />
            )}
            <LogoLink isCorporate={isCorporate} partnerData={partnerData} whiteLabelProgramConfig={whiteLabelProgram?.program_config} />
            {showSearchBar ? (
                <HideOnMobileWrapper style={{ width: '70%' }}>
                    <DynamicHeaderSearchBar />
                </HideOnMobileWrapper>
            ) : null}
        </HeaderTopContentLeft>
    )
}

export default HeaderLogoAndMobileNav
