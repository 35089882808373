import { TextAreaInputWithLabel } from '@/src/common/components/elements/Forms/TextAreaInputWithLabel'
import Button from '@/src/common/components/marketing/buttons/Button'
import HeadlineEyebrow from '@/src/common/components/marketing/headlines/HeadlineEyebrow'
import { styled, theme } from '@/src/stitches.config'

export const PGPlusRedemptionContainer = styled('div', {
    width: '$default',
    maxWidth: '100vw',
    minHeight: '50vh',
    margin: 'auto',
    '.pb-15': {
        pb: 15,
    },
    '.header': {
        fontWeight: 'bold',
        fontSize: '1.15rem',
        mb: 20,
    },
    '.gradient': {
        background: `-webkit-linear-gradient(83.53deg, ${theme.colors.primary} 18.47%, ${theme.colors.secondary} 78%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    '.error': {
        color: '$error',
        mt: 20,
        p: 10,
        br: 5,
        background: '#FFCDD2',
        '& a': {
            textDecoration: 'underline',
            color: '$secondary',
        },
    },
})

export const StyledForm = styled('div', {
    width: 450,
    maxWidth: '100%',
    display: 'flex',
    fd: 'column',
    gap: 15,
})

export const StyledFieldWrapper = styled('div', {
    display: 'block',
    mb: 15,
    maxWidth: '100%',
    position: 'relative',
    '& button': {
        pl: '7px',
        pr: '7px',
    },
    '.brand-search': {
        p: 0,
        width: 500,
        maxWidth: '100%',
    },
    '.ais-SearchBox-loadingIndicator': {
        position: 'absolute',
        top: '5%',
        right: '10%',
    },
    '.brand-error': {
        mt: 0,
        mb: 10,
    },
    variants: {
        maxWidth: {
            'default-max': {
                maxWidth: '100%',
            },
            'half-width': {
                flex: 1,
            },
        },
        variant: {
            standalone: {
                width: '100%',
            },
            default: {
                width: 450,
            },
        },
    },
    defaultVariants: {
        maxWidth: 'default-max',
    },
})

export const StyledList = styled('ul', {
    pl: 35,
    mt: 0,
    '& li': {
        fontWeight: 'bold',
        lh: '1.5rem',
    },
})

export const StyledButton = styled(Button, {
    display: 'block',
    width: '330px !important',
    maxWidth: '100%',
    mb: 10,
    fontSize: '.9rem !important',
    '@media (min-width: 375px)': {
        fontSize: '1rem !important',
    },
    '&:disabled': {
        color: '$white',
    },
})

export const StyledTextArea = styled(TextAreaInputWithLabel, {
    height: '150px',
    background: '$white',
})

export const FAButton = styled('button', {
    br: '50%',
    cursor: 'pointer',
    variants: {
        variant: {
            primary: {
                background: '$primaryLight',
                color: '$primary',
                border: '1px solid $primaryLight',
                '&:hover, :active': {
                    border: '1px solid $primary',
                    background: '$white',
                    '& svg': {
                        border: 'none',
                    },
                },
            },
            simple: {
                background: '$white',
                color: '$darkest',
                border: '1px solid $darkest',
                '&:hover, :active': {
                    border: '1px solid $primary',
                    background: '$primaryLight',
                    color: '$primary',
                    '& svg': {
                        border: 'none',
                    },
                },
            },
        },
        size: {
            regular: {
                py: 6,
                px: 10,
                fontSize: 15,
            },
            small: {
                py: 1,
                px: 6,
                fontSize: 12,
            },
        },
    },
    defaultVariants: {
        variant: 'primary',
        size: 'regular',
    },
})

export const LeftContentWrapper = styled('div', {
    width: '100%',
    padding: '40px 5% 60px',
    '@media (min-width: 765px)': {
        padding: '50px 3% 60px',
        width: '50%',
    },
    '@media (min-width:1500px)': {
        px: 15,
    },
})

export const RightContentWrapper = styled('div', {
    width: '100%',
    display: 'flex',
    fd: 'column',
    ai: 'center',
    padding: '10px 5%',
    '@media (min-width: 765px)': {
        p: '10px 40px 60px',
        width: '50%',
    },
    variants: {
        backgroundColor: {
            default: {
                background: '$lightest',
            },
            none: {},
        },
    },
    defaultVariants: {
        backgroundColor: 'default',
    },
    '.authcode-container': {
        width: '100%',
        maxWidth: 270,
    },
})

export const LoginContentWrapper = styled('div', {
    width: '90%',
    display: 'flex',
    fd: 'column',
    ai: 'center',
    backgroundColor: '$lightest',
    '@md': {
        width: '50%',
    },
})

export const BackButton = styled('div', {
    display: 'flex',
    ai: 'center',
    width: 'max-content',
    cursor: 'pointer',
    mr: '30px',
    variants: {
        marginBottom: {
            default: {},
            'manual-form': {
                marginBottom: 25,
            },
            'mb-20': {
                marginBottom: 20,
            },
        },
        marginTop: {
            default: {},
            marginTopOnXS: {
                '@media (max-width: 767px)': {
                    marginTop: 15,
                },
            },
            marginTop30OnXS: {
                '@media (max-width: 767px)': {
                    marginTop: 30,
                },
            },
            marginTop30: {
                marginTop: 30,
            },
        },
    },
    defaultVariants: {
        marginBottom: 'default',
        marginTop: 'default',
    },
})

export const BlueLine = styled('div', {
    height: 1,
    width: 50,
    border: '1px solid $secondary',
    mt: 35,
    variants: {
        margin: {
            'margin-x-auto': {
                margin: '35px auto auto',
            },
            'margin-top': {
                margin: '35px unset unset',
            },
            'no-margin': {
                margin: 'unset',
            },
        },
    },
    defaultVariants: {
        margin: 'margin-top',
    },
})

export const ContentWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    jc: 'space-between',
    ai: 'center',
    '@media (min-width: 765px)': {
        flexDirection: 'row',
        ai: 'unset',
    },
    variants: {
        width: {
            default: {
                width: '100%',
                maxWidth: '100%',
            },
            success: {
                width: '$default',
                maxWidth: '$defaultMax',
                margin: 'auto',
            },
        },
    },
    defaultVariants: {
        width: 'default',
    },
})

export const GradientText = styled('p', {
    background: `-webkit-linear-gradient(83.53deg, ${theme.colors.primary} 18.47%, ${theme.colors.secondary} 78%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '1.45rem',
    fontWeight: 'bold',
    paddingBottom: '0px !important',
    m: 0,
    variants: {
        size: {
            sm: {
                fontSize: '1.45rem',
            },
            md: {
                fontSize: '1.75rem',
            },
            lg: {
                fontSize: '2rem',
            },
            xl: {
                fontSize: '2rem',
                '@sm': {
                    fontSize: '2.25rem',
                },
            },
        },
    },
})

export const SmallText = styled('div', {
    fontSize: '.85rem',
    lineHeight: '1.25rem',
    width: 460,
    maxWidth: '100%',
    mt: 30,
    '& a': {
        textDecoration: 'underline',
        color: '$primary',
        fontWeight: 'bold',
    },
})

export const StyledH1 = styled('h1', {
    fontSize: '2.5rem',
    maxWidth: '100%',
    '@media (max-width:425px)': {
        fontSize: '2.2rem',
    },
    '@media (min-width: 925px)': {
        width: 550,
        fontSize: '3rem',
    },
    variants: {
        variant: {
            'extra-bold-caps': {
                textTransform: 'uppercase',
                fontWeight: 900,
            },
        },
        marginTop: {
            none: {
                mt: 0,
            },
        },
    },
})

export const StyledEyebrow = styled(HeadlineEyebrow, {
    width: '$default',
    maxWidth: '$defaultMax',
    margin: 'auto',
    variants: {
        marginTop: {
            default: {
                mt: 50,
                '@md': {
                    mt: 0,
                },
            },
            '30-on-all-screens': {
                mt: 30,
            },
        },
    },
    defaultVariants: {
        marginTop: 'default',
    },
})

export const ModalContent = styled('div', {
    width: '100%',
    height: '100%',
    py: 15,
    '.header': {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        mt: 0,
    },
    pt: 5,
    '.body-text': {
        pb: 10,
    },
    '.gradient': {
        background: `-webkit-linear-gradient(83.53deg, ${theme.colors.primary} 18.47%, ${theme.colors.secondary} 78%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
})

export const GiftDetailsWrapper = styled('div', {
    display: 'flex',
    fd: 'column',
    '@media (min-width: 765px)': {
        fd: 'row',
    },
    '& a': {
        color: '$primary',
        fontWeight: 'bold',
    },
})

export const DefaultWrapper = styled('div', {
    width: '$default',
    maxWidth: '$defaultMax',
    minHeight: '50vh',
    margin: 'auto',
})

export const SuccessLeftContentWrapper = styled(LeftContentWrapper, {
    pt: '3%',
    pb: '5%',
    px: 0,
    '& p': {
        lineHeight: '1.75rem',
        pb: 15,
        '@lg': {
            width: 500,
            maxWidth: '100%',
        },
    },
})

export const SuccessRightContentWrapper = styled(RightContentWrapper, {
    pb: 50,
    backgroundColor: '$white',
    display: 'flex',
    jc: 'flex-start',
    ai: 'center',
    '@media (min-width: 765px)': {
        pt: 50,
    },
})

export const LoadingWrapper = styled(DefaultWrapper, {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '87vh',
    '@lg': {
        minHeight: '78vh',
    },
})

export const HeaderWithButtonWrapper = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    fd: 'row',
    '@media (max-width: 345px)': {
        gap: 20,
    },
})

export const PostItWrapper = styled('div', {
    display: 'flex',
    jc: 'center',
    position: 'relative',
    height: 270,
    mt: '5%',
    //@todo uncomment when social share icons are added in
    // mb: 40,
})

export const ShowMoreInfo = styled('div', {
    display: 'flex',
    fd: 'row',
    jc: 'space-between',
    ai: 'center',
    mt: '40px',
    borderBottom: '3px solid $primaryLight',
    pb: '3px',
    cursor: 'pointer',
})

export const MoreInfoText = styled('div', {
    fontWeight: 'bold',
    fontSize: '1.15rem',
    color: `${theme.colors.darkest}`,
})

export const EmphasizedH3Text = styled('h3', {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lh: '25px',
    letterSpacing: '2px',
    color: `${theme.colors.dark}`,
})

export const PGPlusContentWrapper = styled('div', {
    display: 'flex',
    width: '100%',
    height: '100%',
    ai: 'flex-end',
    padding: '5%',
    fontSize: 12,
    '@media (min-width: 421px)': {
        fontSize: 16,
        p: '20px 20px 5.5%',
    },
    '& p': {
        width: 'fit-content',
        maxWidth: '45%',
        color: '$white',
        letterSpacing: '1px',
        textShadow: '0px 0px 2px #000',
        m: 0,
        p: 0,
        transform: 'scale(1, 1.3)',
    },
})
