import NextLink from 'next/link'
import { styled, theme } from '@/src/stitches.config'
import Image from 'next/legacy/image'
import { Text } from '@/src/common/components/elements/Text/Text'
import StarRating from '@/src/common/components/marketing/reviews/StarRating'
import {
    SALES_PHONE_NUMBER_DATA_ID,
    SALES_PHONE_NUMBER_FORMATTED,
    SALES_PHONE_NUMBER_TEL,
    SUPPORT_PHONE_NUMBER_FORMATTED,
    SUPPORT_PHONE_NUMBER_TEL,
} from '@/src/common/components/marketing/text/PhoneNumbers/strings'
import { SocialShareIcons } from '@/src/common/components/marketing/buttons/SocialShareIcons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import {
    FooterBottom,
    FooterBottomLinks,
    FooterBottomLink,
    StyledLink,
    ImageWrapper,
    TermsAndConditionsText,
    FooterBottomLogos,
} from '@/src/modules/footer/StyledComponents'

interface IFooterLegalProps {
    customDisclaimer?: string
}

export const ConsumerAndCorporateFooterLegal = ({
    customDisclaimer,
}: IFooterLegalProps) => {
    const currentYear = new Date().getFullYear()

    return (
        <>
            <FooterBottom>
                <FooterBottomLinks>
                    <div>
                        &copy;
                        {new Date().getFullYear()} Perfect Gift, LLC &#8212;
                        Part of{' '}
                        <StyledLink
                            rel="noopener"
                            target="_blank"
                            href="https://www.wolfe.com/"
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            The Wolfe Companies, LLC
                        </StyledLink>
                        . All rights reserved.
                    </div>
                    <NextLink
                        href={`${process.env.NEXT_PUBLIC_SITE_URL}/privacy-policy`}
                        passHref
                        legacyBehavior
                    >
                        <FooterBottomLink>Privacy Policy</FooterBottomLink>
                    </NextLink>
                    <NextLink
                        href={`${process.env.NEXT_PUBLIC_SITE_URL}/terms-of-use`}
                        passHref
                        legacyBehavior
                    >
                        <FooterBottomLink>Terms of Use</FooterBottomLink>
                    </NextLink>
                    <NextLink
                        href={`${process.env.NEXT_PUBLIC_SITE_URL}/terms-of-service`}
                        passHref
                        legacyBehavior
                    >
                        <FooterBottomLink>Terms of Service</FooterBottomLink>
                    </NextLink>
                    <NextLink
                        href={`${process.env.NEXT_PUBLIC_SITE_URL}/accessibility`}
                        passHref
                        legacyBehavior
                    >
                        <FooterBottomLink>
                            Accessibility Statement
                        </FooterBottomLink>
                    </NextLink>
                    <NextLink
                        href={`${process.env.NEXT_PUBLIC_SITE_URL}/sitemap`}
                        passHref
                        legacyBehavior
                    >
                        <FooterBottomLink>Sitemap</FooterBottomLink>
                    </NextLink>
                    <NextLink
                        href={`${process.env.NEXT_PUBLIC_SITE_URL}/cardholder-agreement`}
                        passHref
                        legacyBehavior
                    >
                        <FooterBottomLink>
                            Cardholder agreement
                        </FooterBottomLink>
                    </NextLink>
                </FooterBottomLinks>
                <FooterBottomLogos>
                    <ImageWrapper>
                        <Image
                            src={'/images/pci_compliant_badge.png'}
                            alt={'PCI Compliant Badge'}
                            width={78}
                            height={32}
                            layout={'fixed'}
                        />
                    </ImageWrapper>
                    <a
                        href="https://www.bbb.org/us/pa/pittsburgh/profile/gift-cards/perfect-gift-llc-0141-71066921"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <Image
                            src="https://seal-westernpennsylvania.bbb.org/seals/blue-seal-120-61-bbb-71066921.png"
                            width="90"
                            height="46"
                            alt="Better Business Bureau Logo"
                        />
                    </a>
                    <ImageWrapper>
                        <Image
                            src={'/images/visa_logo.png'}
                            alt={'Visa logo'}
                            width={59}
                            height={19}
                            layout={'fixed'}
                        />
                    </ImageWrapper>
                    <ImageWrapper>
                        <Image
                            src={'/images/mc_logo.png'}
                            alt={'Mastercard logo'}
                            width={59}
                            height={19}
                            layout={'fixed'}
                        />
                    </ImageWrapper>
                </FooterBottomLogos>
                {/* @TODO Add Norton badge once available  */}
            </FooterBottom>
            {customDisclaimer ? (
                <TermsAndConditionsText
                    dangerouslySetInnerHTML={{
                        __html: customDisclaimer?.replace(
                            /{year}/g,
                            currentYear.toString()
                        ),
                    }}
                />
            ) : (
                <TermsAndConditionsText>
                    The PerfectGift Visa&reg; Gift Card and the Digital Visa
                    Gift Card are issued by Sutton Bank&reg;, Member FDIC,
                    pursuant to a license from Visa U.S.A. Inc. No cash access
                    or recurring payments. Card can be used everywhere Visa
                    debit cards are accepted. Visa Digital Account can be
                    redeemed at every Internet, mail order, and telephone
                    merchant everywhere Visa debit cards are accepted. Terms,
                    conditions and expiration apply. The PerfectGift
                    Mastercard&reg; Reward Cards are issued by Sutton Bank&reg;,
                    Member FDIC, pursuant to a license from Mastercard
                    International Incorporated. No cash access or recurring
                    payments. Card can be used everywhere Mastercard debit cards
                    are accepted. Mastercard Digital Account can be redeemed at
                    every Internet, mail order, and telephone merchant
                    everywhere Mastercard debit cards are accepted. Terms,
                    conditions and expiration apply.
                </TermsAndConditionsText>
            )}
        </>
    )
}
export default ConsumerAndCorporateFooterLegal
