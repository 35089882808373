import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useMemo,
} from 'react'
import { Keyboard } from '@capacitor/keyboard'
import { safeCapacitorCall } from '@/src/common/utilities/capacitorUtils'

type KeyboardContextType = {
    isKeyboardOpen: boolean
    setIsKeyboardOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const KeyboardContext = createContext<KeyboardContextType | undefined>(
    undefined
)

export const KeyboardProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)

    useEffect(() => {
        const handleKeyboardShow = () => {
            setIsKeyboardOpen(true)
        }
        const handleKeyboardHide = () => {
            setIsKeyboardOpen(false)
        }

        safeCapacitorCall(() =>
            Keyboard.addListener('keyboardDidShow', handleKeyboardShow)
        )
        safeCapacitorCall(() =>
            Keyboard.addListener('keyboardDidHide', handleKeyboardHide)
        )

        return () => {
            safeCapacitorCall(() => Keyboard.removeAllListeners())
        }
    }, [])

    const value = useMemo(
        () => ({ isKeyboardOpen, setIsKeyboardOpen }),
        [isKeyboardOpen]
    )

    return (
        <KeyboardContext.Provider value={value}>
            {children}
        </KeyboardContext.Provider>
    )
}

export const useKeyboardContext = (): KeyboardContextType => {
    const context = useContext(KeyboardContext)
    if (context === undefined) {
        throw new Error(
            'useKeyboardContext must be used within a KeyboardProvider'
        )
    }
    return context
}
