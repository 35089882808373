export const CORPORATE_BRANDS_PAGE: string = '/corporate/brands'
export const BRAND_PAGE: string = '/brands'
export const PG_PLUS_BUILD_PAGE: string = '/perfectgift-plus/build'
export const VIRTUAL_VISA_PAGE = '/virtual-visa-gift-cards'
export const CHECKOUT_PATH: string = '/checkout'
export const PG_PLUS_REDEMPTION_PATH: string = '/redeem-gift?token='
export const EMAIL_DELIVERY: string = 'email'
export const TEXT_DELIVERY: string = 'text'
export const PHYSICAL_DELIVERY: string = 'physical'
export const PHYSICAL_SMB_DELIVERY: string = 'physical-smb'
export const PHONE_REG_EXP: RegExp = /^\(\d{3}\)\s\d{3}-\d{4}$/
export const PHONE_REG_WITH_AREA_ONLY_NUMBERS: RegExp = /^\+\d+\d{3}\d{3}\d{4}$/
export const MIN_PG_PLUS_CARD_LOAD: number = 5
export const MAX_PG_PLUS_SINGLE_CARD_LOAD: number = 100
export const DEFAULT_BUY_PAGE: string = '/visa-gift-cards'

export const CARD_LINK: string = 'card-link'
export const BRANDED_EGIFT: string = 'branded-egift'
export const UNBRANDED_TO_LINK: string = 'unbranded-to-branded'
export const UNBRANDED_TO_EGIFT: string = 'unbranded-to-egift'
export const CHANGE_BRAND: string = 'change-brand'
export const PLASTIC_REDEMPTION: string = 'plastic'
export const ZELLE_REDEMPTION: string = 'zelle'
export const REGIFT_REDEMPTION: string = 'regift'
export const UNENROLL_CARD_FROM_LINK: string = 'unenroll-card'
export const EDIT_SAVED_CARDS: string = 'edit-saved-cards'

// remove /test from this AND move page out of test directory
export const REFER_A_FRIEND_PAGE: string = '/test/refer-a-friend'

export const DEFAULT_ORDER_STATUS_PAGE: string = '/order-status'

export const PARTNER_PATH: string = '/partner'
export const PARTNER_BRAND_PAGE: string = '/partner/brands'
export const PARTNER_CHECKOUT_PAGE: string = '/partner/checkout'
export const PARTNER_CONFIRMATION_PAGE: string =
    '/partner/checkout/confirmation'
export const PARTNER_LOGIN_PAGE: string = '/partner/login'
export const PARTNER_ORDER_STATUS_PAGE: string = '/partner/order-status'
export const COBRAND_BAC_PAGE: string = '/gift-cards-for-business'
export const SMALL_BUSINESS_PAGE: string = '/small-business'

export const WHITELABEL_PURCHASE_PAGE: string = '/purchase'

export const SINGLE_MERCHANT_PARTNER_PGC_HEADER_PAGES: string[] = [
    PARTNER_CHECKOUT_PAGE,
    PARTNER_CONFIRMATION_PAGE,
    PARTNER_ORDER_STATUS_PAGE,
    PARTNER_LOGIN_PAGE,
]

export type TUNIFIED_RECIPIENT_DELIVERY = {
    // send via phone, email, or shipping
    type:
        | typeof TEXT_DELIVERY
        | typeof EMAIL_DELIVERY
        | typeof PHYSICAL_DELIVERY
        | typeof PHYSICAL_SMB_DELIVERY
        | null
    // phone or email
    recipientContact?: string
}
