import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import BasicAppHeader from '@/src/common/components/elements/Layout/Navigation/NativeApps/headers/BasicAppHeader'
import { hideBackArrowPaths } from '@/src/common/components/elements/Layout/Navigation/NativeApps/headers/hideBackArrowPaths'
import { useIsNative } from '@/src/common/contexts/IsNativeContext'

const AppHeader = () => {
    const router = useRouter()
    const [showBackArrow, setShowBackArrow] = useState(false)
    const isNative = useIsNative()

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            //ignore this response from the router
            if (url === '/[[...page]]') {
                return
            }

            if (isNative && url === '/') {
                router.replace('/native-app/app-homepage')
            }
            const shouldHideBackArrow =
                hideBackArrowPaths.includes(url) || //hide the back arrow if the current route is in the hideBackArrowPaths array
                url.startsWith('/checkout/confirmation') //or if the current route starts with '/checkout/confirmation'
            setShowBackArrow(!shouldHideBackArrow)
        }

        // Initial check
        handleRouteChange(router.pathname)

        // Listen to route changes
        router.events.on('routeChangeComplete', handleRouteChange)

        // Clean up the event listener on component unmount
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router])

    return (
        <BasicAppHeader
            onBackClick={() => router.back()}
            showBackArrow={showBackArrow}
        />
    )
}

export default AppHeader
