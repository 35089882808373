import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { onAuthStateChanged, User } from 'firebase/auth'
import { firebaseAuth } from '@/config/firebaseClientConfig'
import { identify } from '@fullstory/browser'

type AuthContextType = {
    user: User | null
    userLoaded: boolean
    refreshUser: () => Promise<void>
    error: string | null
    lastVerificationCheck: number
}

const AuthContext = createContext<AuthContextType>({
    user: null,
    userLoaded: false,
    refreshUser: async () => {},
    error: null,
    lastVerificationCheck: 0,
})

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<User | null>(null)
    const [userLoaded, setUserLoaded] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [lastVerificationCheck, setLastVerificationCheck] =
        useState<number>(0)

    const refreshUser = async () => {
        if (firebaseAuth.currentUser) {
            try {
                await firebaseAuth.currentUser.reload()
                // Create a safe copy of the user object without using JSON methods
                setUser(firebaseAuth.currentUser)
                setLastVerificationCheck(Date.now())
                setError(null)
            } catch (err) {
                const message =
                    err instanceof Error
                        ? err.message
                        : 'Failed to refresh user data'
                setError(message)
                console.error('Error refreshing user:', err)
            }
        }
    }

    const value = useMemo(
        () => ({ user, userLoaded, refreshUser, error, lastVerificationCheck }),
        [user, userLoaded, error, lastVerificationCheck]
    )

    const handleWindowFocus = () => {
        refreshUser().catch((err) => {
            console.error('Error refreshing user on window focus:', err)
        })
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            firebaseAuth,
            (user) => {
                setUser(user)
                setUserLoaded(true)
                setError(null)

                // Only identify with FullStory if user is logged in and analytics is allowed
                // Consider adding a check for user consent here
                if (user && typeof window !== 'undefined') {
                    try {
                        identify(user.uid, {
                            displayName: user.displayName || 'Unknown',
                            email: user?.email || '',
                            phone: user?.phoneNumber || '',
                        })
                    } catch (e) {
                        console.error('FullStory error:', e)
                    }
                }
            },
            (err) => {
                console.error('Firebase auth state error:', err)
                setError(err.message)
                setUserLoaded(true)
            }
        )

        window.addEventListener('focus', handleWindowFocus)

        return () => {
            unsubscribe()
            window.removeEventListener('focus', handleWindowFocus)
        }
    }, [])

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useUser = () => {
    const { user, refreshUser, userLoaded, error, lastVerificationCheck } =
        useContext(AuthContext)

    return {
        user,
        refreshUser,
        isLoaded: userLoaded,
        isLoggedIn: !!user,
        isVerified: isUserVerified(user),
        authProviders: getUserAuthProviders(user),
        userLoginMethod: getUserLoginMethod(user),
        error,
        lastVerificationCheck,
    }
}

function isUserVerified(user: User | null): boolean {
    if (!user) return false
    if (user.emailVerified) return true
    if (user.phoneNumber) return true

    // Check for social providers which are pre-verified
    const socialProviders = [
        'facebook.com',
        'google.com',
        'apple.com',
        'twitter.com',
    ]
    if (
        user.providerData?.some(
            (provider) =>
                provider && socialProviders.includes(provider.providerId)
        )
    ) {
        return true
    }

    return false
}

function getUserAuthProviders(user: User | null): string[] {
    if (!user) return []

    // Extract all provider IDs from the user's providerData
    const providers =
        user.providerData?.map((provider) => provider.providerId) || []

    // Add password provider if email exists but isn't in providers
    if (user.email && !providers.includes('password')) {
        providers.push('password')
    }

    // Add phone provider if phoneNumber exists
    if (user.phoneNumber && !providers.includes('phone')) {
        providers.push('phone')
    }

    return [...new Set(providers)] // Remove duplicates
}

function getUserLoginMethod(user: User | null): string {
    if (!user) return ''

    // Return the primary identifier - either email or phone
    if (user.email) return user.email
    if (user.phoneNumber) return user.phoneNumber

    // If no direct identifiers, check provider data
    const firstProvider = user.providerData?.[0]
    if (firstProvider) {
        // Return provider type and ID
        return `${firstProvider.providerId}:${firstProvider.uid}`
    }

    return user.uid || ''
}
