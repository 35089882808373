import prisma from '@/lib/prisma'
import { TkYCStatus, TOrderLimitsCodes } from '@/src/modules/kyc/types'

export const getIsSMBUser = async (userId: number): Promise<boolean> => {
    const kycApplication = await prisma.knowYourCustomerApplication.findFirst({
        where: {
            user_id: {
                equals: userId,
            },
        },
    })
    return !!(kycApplication && kycApplication.status !== TkYCStatus.REJECTED)
}

export function determineIfKYCIsOverWeeklyLimit(
    code: TOrderLimitsCodes | undefined
): boolean {
    if (!code) return false

    return [
        TOrderLimitsCodes.ORDER_BREAKS_KYC_WEEK_LIMIT,
        TOrderLimitsCodes.KYC_OVER_WEEK_LIMIT,
    ].includes(code)
}

export function determineIfConsumerOrderIsOverWeeklyLimit(
    code: TOrderLimitsCodes | undefined
): boolean {
    if (!code) return false

    return [
        TOrderLimitsCodes.ORDER_BREAKS_WEEK_LIMIT,
        TOrderLimitsCodes.OVER_WEEK_LIMIT,
    ].includes(code)
}
